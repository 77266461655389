import {
    capitalizeFirstLetterDropLast,
    capitalizeFirstLetter,
    formIsLoadingCollectionName,
    formPluralizedCollectionName,
    formLoadMethodCollectionName
} from '../util/helpers';

export const collectionNames = ['producer', 'varietal', 'type', 'tag', 'wine'];

export const pluralizedCollectionNames = collectionNames.map(clnName =>
    formPluralizedCollectionName(clnName)
);

export const isLoadingCollectionNames = collectionNames.map(clnName =>
    formIsLoadingCollectionName(clnName)
);

export const loadCollectionMethodNames = collectionNames.map(clnName =>
    formLoadMethodCollectionName(clnName)
);
