import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import AuthProvider from './auth/AuthProvider';
import DataProvider from './data/DataProvider';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StateProvider } from './store/store.js';
import 'typeface-roboto';
// A theme with custom primary and secondary color.
//red/yellow
//https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=b71c1c&secondary.color=FFEB3B
//red/grey/blue
//https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=b71c1c&secondary.color=546E7A

const theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette: {
        primary: {
            main: '#b71c1c',
            light: '#f05545',
            dark: '#7f0000'
        },
        secondary: {
            //main: '#ffd740' //mustardish
            main: '#546e7a',
            light: '#819ca9',
            dark: '#29434e'
        }
    }
});

// const app = (
//     <StateProvider>
//         <App />
//     </StateProvider>
// );

class WrappedApp extends Component {
    render() {
        return (
            <StateProvider>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                        <AuthProvider>
                            <DataProvider>
                                <App />
                            </DataProvider>
                        </AuthProvider>
                    </Router>
                </MuiThemeProvider>
            </StateProvider>
        );
    }
}
ReactDOM.render(<WrappedApp />, document.getElementById('root'));
// export default App;
