import React, { useContext } from 'react';
import { store } from '../store/';
//import Auth from '../../modules/Auth';
//import { AuthContextConsumer } from '../auth/AuthProvider';
//import { AuthContext } from '../auth/AuthProvider';

import {
    Route,
    Redirect
    //withRouterk
} from 'react-router-dom';

export function AuthorizedRoute({ component: Component, ...rest }) {
    const { appState } = useContext(store);
    const { user } = appState;
    const { isAuthenticated } = user;

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}
// export const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (
//       Auth.isUserAuthenticated() ? (
//         <Component {...props} {...rest} />
//       ) : (
//         <Redirect to={{
//           pathname: '/',
//           state: { from: props.location }
//         }}/>
//       )
//     )}/>
//   )

export function LoggedOutRoute({ component: Component, ...rest }) {
    const { appState } = useContext(store);
    const { user } = appState;
    const { isAuthenticated } = user;
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                ) : (
                    <Component {...props} {...rest} />
                )
            }
        />
    );
}

// export const LoggedOutRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (
//       Auth.isUserAuthenticated() ? (
//         <Redirect to={{
//           pathname: '/',
//           state: { from: props.location }
//         }}/>
//       ) : (
//         <Component {...props} {...rest} />
//       )
//     )}/>
//   )

export const PropsRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => <Component {...props} {...rest} />} />
);
