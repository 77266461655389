import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { BurgundyBottle } from '../icons/SVGIcons';
import { green, red, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    slot: {
        margin: 4,
    },
    buttonStyles: {
        width: 50,
        height: 50,
    },
    buttonStylesRackChange: {
        borderColor: yellow[800],
    },
    buttonStylesDrink: {
        borderColor: green[700],
        background: green[100],
    },
    buttonStylesRackRemove: {
        borderColor: red[700],
        background: red[100],
    },
    buttonStylesSelectedWine: {
        // height: 50,
        borderColor: 'black',
    },
    emptySlotIcon: {
        fontSize: 30,
    },
    bottleIsOfSelectedWine: {
        color: green[700],
        fontSize: 26,
    },
}));

const useBaseButtonStyles = makeStyles((theme) => ({
    root: {
        // padding: '5px 6px',
        minWidth: 50,
    },
}));

export default function BottleButton({ bottle, onClick, disabled, ...rest }) {
    const classes = useStyles();
    const baseButtonClasses = useBaseButtonStyles();
    const buttonProps = {
        onClick: onClick,
        variant: 'outlined',
        disabled,
    };

    const buttonClasses = classNames({
        [classes.buttonStyles]: true,
        [classes.buttonStylesRackChange]: bottle.stagedForAdd,
        [classes.buttonStylesRackRemove]: bottle.stagedForRemove,
        [classes.buttonStylesDrink]: bottle.stagedForDrinking,
        [classes.buttonStylesSelectedWine]: bottle.isOfSelectedWine,
    });

    const bottleClasses = classNames({
        [classes.bottleIsOfSelectedWine]: bottle.isOfSelectedWine,
    });

    const isOfSelectedWine = bottle.isOfSelectedWine;
    // this scroll behavior probalby should be at a different level and incorporate the out of rack bottles as well
    const ref = React.useRef();
    React.useEffect(() => {
        if (isOfSelectedWine) {
            if (ref.current) {
                requestAnimationFrame(() => {
                    //some other ideas
                    //var topOfElement = ref.current.offsetTop - ;
                    //window.scroll({ top: topOfElement, behavior: "smooth" });
                    //scroll-margin-top: 100px;
                    ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                });
            }
        }
    }, [isOfSelectedWine]);
    const fontSize = isOfSelectedWine ? 'inherit' : 'small';
    return (
        <Button ref={ref} classes={baseButtonClasses} {...buttonProps} className={buttonClasses}>
            <BurgundyBottle className={bottleClasses} fontSize={fontSize} />
        </Button>
    );
}

// import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
