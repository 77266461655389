import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import WineDetails from './WineDetails';
import Paper from '@material-ui/core/Paper';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 10
    }
}));

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        //backgroundColor: '#f5f5f9',
        backgroundColor: theme.palette.background.paper,
        color: 'rgba(0, 0, 0, 0.8)',
        maxWidth: 450
        // fontSize: theme.typography.pxToRem(12)
        //border: '1px solid #dadde9'
    }
}))(Tooltip);

// RackInfo (for hover)
function RackTooltip({ children, ...rest }) {
    const classes = useStyles();
    return (
        <HtmlTooltip
            title={
                <Paper className={classes.root}>
                    <WineDetails variant="tooltip" wine={rest.wine} />
                </Paper>
            }
        >
            {children}
        </HtmlTooltip>
    );
}

// function RackTooltipDetail({ wineDetails }) {
//     const classes = useStyles();

//     return (
//         <React.Fragment>
//             <Typography color="inherit">Tooltip Inner</Typography>
//             <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
//             {"It's very engaging. Right?"}
//         </React.Fragment>
//     );
// }

export default RackTooltip;
