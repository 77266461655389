import React, { Component, useContext } from 'react';
import { stall, capitalizeFirstLetterDropLast, capitalizeFirstLetter } from '../util/helpers';
import { store, actions } from '../store/';
import { collectionNames, pluralizedCollectionNames } from '../store/constants';
import { formPluralizedCollectionName, formLoadMethodCollectionName } from '../util/helpers';

//todo: rename to apiContext and apiContextProvider

// Create a new context for the app
export const DataContext = React.createContext('Data');
export const DataContextProvider = DataContext.Provider;
export const DataContextConsumer = DataContext.Consumer;

const fetchOptions = {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    credentials: 'include'
};

class DataProvider extends Component {
    static contextType = store;

    constructor(props) {
        super(props);
        this.state = {};
        // wines is special purpose
        collectionNames
            .filter(n => n !== 'wine')
            .forEach(clnName => {
                let pluralized = `${clnName}s`;
                let addMethodName = `add${capitalizeFirstLetter(clnName)}`;
                let loadMethodName = formLoadMethodCollectionName(clnName);
                //note this will bind as well
                this[loadMethodName] = () => this._loadCollection(pluralized);
                this[addMethodName] = async item => this._addItemToCollection(pluralized, item);
                this.state[loadMethodName] = this[loadMethodName];
                this.state[addMethodName] = this[addMethodName];
            });
        //wines is slightly different b/c it is non-standard
        this.saveWine = this.saveWine.bind(this);
        this.loadWines = this.loadWines.bind(this);
        this.refreshSelectedWine = this.refreshSelectedWine.bind(this);

        this.state.saveWine = this.saveWine;
        this.state.loadWines = this.loadWines;
        this.state.deleteWine = this.deleteWine.bind(this);
        this.state.loadAllCollections = this.loadAllCollections.bind(this);
        this.state.removeInventoryPositions = this.removeInventoryPositions.bind(this);
        this.state.drinkWine = this.drinkWine.bind(this);

        this.state.adjustInventory = this.adjustInventory.bind(this);
        this.state.selectWine = this.selectWine.bind(this);
        this.state.reportApi = this.reportApi.bind(this);
        this.state.updateInventoryPosition = this.updateInventoryPosition.bind(this);
    }

    componentDidMount() {
        //this.loadAllCollections();
    }

    loadAllCollections() {
        const { appState } = this.context;
        const { user } = appState;
        if (user.isAuthenticated) {
            collectionNames.forEach(collectionName => {
                const pluralCollectionName = formPluralizedCollectionName(collectionName);
                if (!appState[pluralCollectionName]) {
                    const loadCollectionName = formLoadMethodCollectionName(collectionName);
                    this[loadCollectionName]();
                }
            });
        }
    }

    async saveWine(data, oldData) {
        const isNew = !data.id;
        const wine = await this._postToApi('saveWine', data);
        if (isNew) {
            await this.addNewDataToCollection('wines', wine, 'name');
        } else {
            const index = this.context.appState.wines.findIndex(w => w.id == data.id);
            await this.updateDataInCollection('wines', wine, index, 'name');
            this.refreshSelectedWine(wine);
        }
        return wine;
    }

    async updateInventoryPosition(data, oldData) {
        const wine = await this._postToApi('updateInventoryPositions', data);
        const index = this.context.appState.wines.findIndex(w => w.id == data.id);
        await this.updateDataInCollection('wines', wine, index, 'name');
        this.refreshSelectedWine(wine);
        return wine;
    }

    async removeInventoryPositions(data, oldData) {
        // since we can remove anything here, this refreshes the whole list.. a little sloppy i guess
        await this._postToApi('removeInventoryPositions', data);
        return this.loadWines();
    }

    async drinkWine(data) {
        await this._postToApi('drinkWine', data);
        await this.loadWines();
    }

    async adjustInventory(data) {
        const wine = await this._postToApi('adjustInventory', data);
        const index = this.context.appState.wines.findIndex(w => w.id == data.id);
        await this.updateDataInCollection('wines', wine, index, 'name');
        this.refreshSelectedWine(wine);
        return wine;
    }

    async deleteWine(wineId) {
        const data = { id: wineId };
        await this._postToApi('deleteWine', data);
        // todo maybe just remove from collection, this is kind of lazy here
        return this.loadWines();
    }

    // call this to update selected wine on relevant changes (maybe there is a better way?)
    refreshSelectedWine(wine) {
        const { appState } = this.context;
        const { selectedWine } = appState;
        if (selectedWine && selectedWine.id == wine.id) {
            this.context.dispatch(actions.setSelectedWineId({ selectedWineId: wine.id }));
        }
    }

    // this is a little different, I'm just going to return this rather than store it in context
    // could probably revisit all the state stuff and move to new hooks/context
    // hopefully don't need this?
    async selectWine(id) {
        this.context.dispatch(actions.setSelectedWineId({ selectedWineId: id }));
    }

    async loadWines() {
        const loadMethodName = `getWines`;
        //this.setState({ isLoadingWines: true });
        const collectionName = 'wines';
        this.context.dispatch(
            actions.setCollectionIsLoading({
                collectionName,
                isLoading: true
            })
        );

        return fetch(`/api/v1/${loadMethodName}`, fetchOptions)
            .then(r => r.json())
            .then(wines => {
                this.context.dispatch(actions.setCollection({ collectionName, collection: wines }));
                // for debugging edit form so we have one
                //this.context.dispatch(actions.setSelectedWineId({ selectedWineId: 1 }));
                //this.context.dispatch(actions.setEditMode({ wineEditMode: true }));
            });
    }

    _loadCollection(collectionName) {
        let loadMethodName = `get${capitalizeFirstLetter(collectionName)}`;
        this.context.dispatch(
            actions.setCollectionIsLoading({
                collectionName: collectionName.slice(0, -1),
                isLoading: true
            })
        );

        return fetch(`/api/v1/${loadMethodName}`, fetchOptions)
            .then(r => r.json())
            .then(collection => this.prepCollection(collection))
            .then(preppedCollection => {
                this.context.dispatch(
                    actions.setCollection({ collectionName, collection: preppedCollection })
                );
            });
    }

    async _addItemToCollection(collectionName, itemName) {
        const bodyObj = {
            name: itemName
        };
        let addMethodName = `add${capitalizeFirstLetterDropLast(collectionName)}`;
        const newItem = await this._postToApi(addMethodName, bodyObj);
        const transformedItem = this.transformForAutoComplate(newItem);
        await this.addNewDataToCollection(collectionName, transformedItem);
        return transformedItem;
    }

    async _postToApi(endPoint, bodyObj, extraOptions = {}) {
        const body = JSON.stringify(bodyObj);
        const postOptions = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST'
        };

        const options = Object.assign({}, fetchOptions, postOptions, extraOptions, { body });
        return fetch(`/api/v1/${endPoint}`, options).then(r => r.json());
    }

    async addNewDataToCollection(collectionName, data, sortProp) {
        const collection = this.context.appState[collectionName];
        const newCollection = [data].concat(collection);
        this.sortCollection(newCollection, sortProp);

        return this.context.dispatch(
            actions.setCollection({ collectionName, collection: newCollection })
        );
    }

    async updateDataInCollection(collectionName, data, index, sortProp) {
        const collection = this.context.appState[collectionName];
        collection[index] = data;
        const newCollection = collection.slice(0);
        this.sortCollection(newCollection, sortProp);
        // return await this.setState({
        //     [key]: newCollection
        // });
        return this.context.dispatch(
            actions.setCollection({ collectionName, collection: newCollection })
        );
    }

    prepCollection(collection) {
        let newCollection = this.transformCollection(collection);
        return this.sortCollection(newCollection);
    }

    //assumes label prop
    sortCollection(collection, sortProp = 'label') {
        collection.sort((a, b) => {
            const nameA = a[sortProp].toUpperCase(); // ignore upper and lowercase
            const nameB = b[sortProp].toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });
        return collection;
    }

    transformCollection(collection) {
        return collection.map(e => this.transformForAutoComplate(e));
    }

    //maybe this moves out?
    transformForAutoComplate(item) {
        //const withStringIds = (arr) => arr.map(e => ({id:e.id.toString(), name:e.name}))
        return { label: item.name, value: item.id.toString() };
    }

    // this is kind of a cheat i guess..
    async reportApi(apiEndPoint, options) {
        return fetch(`/api/v1/reports/${apiEndPoint}`, fetchOptions).then(r => r.json());
        // .then(d => {
        //     //this.context.dispatch(actions.setCollection({ collectionName, collection: wines }));
        //     // for debugging edit form so we have one
        //     //this.context.dispatch(actions.setSelectedWineId({ selectedWineId: 1 }));
        //     //this.context.dispatch(actions.setEditMode({ wineEditMode: true }));
        // });
    }

    render() {
        return <DataContextProvider value={this.state}>{this.props.children}</DataContextProvider>;
    }
}

export default DataProvider;
