import React, { Component } from 'react';
import { store, actions } from '../store/';

export const AuthContext = React.createContext('auth');
export const AuthContextProvider = AuthContext.Provider;
export const AuthContextConsumer = AuthContext.Consumer;

// Creates a provider Component
class AuthProvider extends Component {
    static contextType = store;

    constructor(props) {
        super(props);
        //this.onOpenPricingModal = this.onOpenPricingModal.bind(this);
        this.checkUser = this.checkUser.bind(this);
        this.setAuthenticated = this.setAuthenticated.bind(this);
        this.googleResponse = this.googleResponse.bind(this);
        this.logout = this.logout.bind(this);

        this.state = {
            checkUser: this.checkUser,
            googleResponse: this.googleResponse,
            logout: this.logout
        };
    }

    componentDidMount() {
        this.checkUser();
    }

    checkUser() {
        const options = {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
            credentials: 'include'
        };
        const url = '/api/v1/userinfo';
        return fetch(url, options)
            .then(r => r.json())
            .then(data => this.setAuthenticated(data));
    }

    setAuthenticated(data) {
        //console.log(data);
        const userData = {
            isAuthenticated: data.loggedin,
            email: data.email
        };
        this.context.dispatch(actions.setUser({ data: userData }));
    }

    googleResponse(response) {
        const tokenBlob = new Blob(
            [JSON.stringify({ access_token: response.accessToken }, null, 2)],
            { type: 'application/json' }
        );
        const options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',
            cache: 'default',
            credentials: 'include'
        };
        fetch('/api/v1/auth/google', options)
            .then(r => r.json())
            .then(data => {
                this.setAuthenticated(data);
            });
    }

    logout() {
        const options = {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
            credentials: 'include'
        };
        const url = '/api/v1/logout';
        fetch(url, options).then(() => {
            this.context.dispatch(actions.unsetUser());
        });
    }

    render() {
        return <AuthContextProvider value={this.state}>{this.props.children}</AuthContextProvider>;
    }
}
export default AuthProvider;
