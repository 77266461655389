import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function BurgundyBottle(props) {
  return (
    <SvgIcon viewBox="-482.025 260.687 26.244 86.709" {...props}>
      <path fill="none" d="M-486 257h34.6v125H-486z" />
      <path d="M-456.6 313.68c0-4.7-1-9.3-2.6-13.7-.9-2.5-2-4.9-2.9-7.4-1.5-4.1-2.3-8.4-2.5-12.9 0-.8 0-1.6-.1-2.5v.1c-.1-2.3-.1-4.6-.1-6.8v-2.5-.3c0-.4.2-.7.3-1.1 0-.2.1-.3.1-.5v-1.5c0-.3-.2-.6-.3-1-.1-.2-.1-.5-.1-.7 0-.4-.2-.6-.5-.7-.4-.1-1-.2-1.4-.2h-4.1c-.4 0-.7.1-1.1.2-.4.1-.6.3-.5.7 0 .2 0 .5-.1.7-.1.3-.2.6-.3 1v1.5c0 .2.1.3.1.5.1.4.3.8.3 1.2 0 3.2-.1 6.3-.2 9.5v1.6c-.1 1.3-.2 2.7-.3 4-.6 4.8-1.9 9.4-3.9 13.8-2.7 5.9-4 12-4 18.4v22.9c0 1.8.1 3.6.2 5.4.1 1.5.8 2.3 2.2 2.6.3.1.7.1 1 .2 4.4.6 8.7.8 13.1.5 1.8-.1 3.6-.4 5.4-.6 1.3-.2 2-.9 2.2-2.3.2-1.2.3-2.4.3-3.6-.1-8.7-.1-17.6-.2-26.5z" />
    </SvgIcon>
  );
}

export function BordeauxBottle(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10,22A1,1 0 0,1 9,21V11C9,9 10,7.25 11,7V2.5A0.5,0.5 0 0,1 11.5,2H12.5A0.5,0.5 0 0,1 13,2.5V7C14,7.25 15,9 15,11V21A1,1 0 0,1 14,22H10Z" />
    </SvgIcon>
  );
}

export function CopyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
    </SvgIcon>
  );
}

export function CutIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9.64 7.64c.23-.5.36-1.05.36-1.64 0-2.21-1.79-4-4-4S2 3.79 2 6s1.79 4 4 4c.59 0 1.14-.13 1.64-.36L10 12l-2.36 2.36C7.14 14.13 6.59 14 6 14c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4c0-.59-.13-1.14-.36-1.64L12 14l7 7h3v-1L9.64 7.64zM6 8c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm0 12c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm6-7.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5.5.22.5.5-.22.5-.5.5zM19 3l-6 6 2 2 7-7V3z" />
    </SvgIcon>
  );
}
