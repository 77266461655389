import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { WineSubDetails } from './WineDetails';
import { DataContext } from '../data/DataProvider';

export default function DrinkDialog({ open, handleClose, bottle = {} }) {
    const wine = (bottle && bottle.wineDetails) || {};
    const { year, producer, name, varietal } = wine;
    const dataContext = useContext(DataContext);
    //const paperProps = { elevation: 12 };
    //PaperProps={paperProps}
    function handleDrink() {
        // const bottles = stagedBottlesToDrink.map((b) => {
        //     return {
        //         bottleId: b.id,
        //         wineId: b.fk_wine,
        //     };
        // });
        const bottles = [
            {
                bottleId: bottle.id,
                wineId: bottle.fk_wine,
            },
        ];
        const dataToSave = {
            bottles,
        };
        dataContext.drinkWine(dataToSave);

        handleClose();
    }

    return (
        bottle && (
            <Dialog open={open} onClose={handleClose} hideBackdrop={true}>
                <DialogTitle>
                    Drink {year} {producer.label} {name} {varietal && varietal.label}?
                </DialogTitle>
                <DialogContent>
                    <WineSubDetails wine={wine} />{' '}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Nevermind
                    </Button>
                    <Button onClick={handleDrink} color="primary" autoFocus>
                        Drink!
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
}
