import React, { useContext } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import WineImage from './WineImage';
import Button from '@material-ui/core/Button';
import AutoComplete from '../util/AutoComplete';
import MarkDownField from '../util/MarkDownField';
import * as Yup from 'yup';
import { fieldToTextField, TextField } from 'formik-material-ui';
import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CustomSnackbar from '../util/CustomSnackbar';
import AddIcon from '@material-ui/icons/Add';
//import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import ImgurUpload from '../util/ImgurUpload';
import InventoryAdjust from './InventoryAdjust';
import { store, actions, selectors } from '../store/';
import { DataContext } from '../data/DataProvider';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Wine name is required.'),
    producer: Yup.object().nullable().required('Producer is required.'),
    year: Yup.number()
        .required('Year is required.')
        .integer('Year must be an integer')
        .min(1900, 'Must be greater than 1900')
        .max(3000, 'Must be less than 3000.'),
    cost: Yup.number().nullable(),
    retail: Yup.number().nullable(),
    tags: Yup.array().nullable(),
    image_url: Yup.string().nullable(),
    description: Yup.string().nullable(),
    bottleCount: Yup.number().nullable().integer('bottleCount must be an integer'),
});

const transformFormFieldsForFullSave = (values) => {
    // note here we want to always send tags b/c empty array means empty on server
    const { cost, retail, producer, type, varietal, ...rest } = values;
    const tags = values.tags || [];
    const payload = {
        ...rest,
        fk_producer: producer ? Number(producer.value) : null,
        fk_type: type ? Number(type.value) : null,
        fk_varietal: varietal ? Number(varietal.value) : null,
        tags: tags.map((t) => Number(t.value)),
        cost: cost ? Number(cost) : null,
        retail: retail ? Number(retail) : null,
    };
    return payload;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 20,
    },
    paperRoot: {
        backgroundColor: theme.palette.background.paper,
        maxWidth: 900,
        padding: 20,
    },
    upperForm: {
        maxWidth: 650,
    },
    title: {
        marginBottom: 4,
        borderBottom: '1px solid rgba(0,0,0,.1)',
    },
    formField: {
        paddingTop: 8,
        margin: 4,
        marginRight: 10,
        //marginTop:8,
        marginBottom: 6,
    },
    formFieldShort: {
        paddingTop: 8,
        marginBottom: 6,
        marginRight: 10,
        maxWidth: '10em',
    },
    formRowExtraTop: {
        marginTop: 8,
    },
    formError: {
        color: 'red',
        //marginTop: 4
    },
    formWidth: {},
    button: {
        color: theme.palette.secondary.dark,
        marginLeft: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export function WineEdit({ formEdit }) {
    const dataContext = useContext(DataContext);
    const { dispatch, appState } = useContext(store);
    const selectedWine = selectors.selectedWineSelector(appState);
    const classes = useStyles();
    const isEditMode = !!selectedWine.id;
    const isAddMode = !isEditMode;
    const title = isEditMode ? 'Edit' : 'Add';
    const buttonText = isEditMode ? 'Save' : 'Add';
    const toastMsg = `${buttonText} successful!`;
    const initialFormValues = { ...selectedWine, bottleCount: 1 };
    const [toastOpen, setToastOpen] = React.useState(false);

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    }
    //todo: do we need this outer grid?
    return (
        <Grid item container direction="column" spacing={2} className={classes.root}>
            <Grid item xs>
                <Paper className={classes.paperRoot}>
                    <Typography className={classes.title} variant="h5">
                        {title} Wine
                    </Typography>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        initialValues={initialFormValues}
                        onSubmit={async (values, formikActions) => {
                            const { saveStrategy, ...restOfFormValues } = values;
                            const payload = transformFormFieldsForFullSave(restOfFormValues);
                            const { setSubmitting, resetForm, setValues } = formikActions;
                            const updatedWine = await dataContext.saveWine(payload);
                            setSubmitting(false);
                            resetForm();
                            setToastOpen(true);
                            if (saveStrategy == 'saveNew') {
                                formEdit(0);
                            } else {
                                formEdit(updatedWine.id);
                            }
                        }}
                        render={(props) => {
                            const {
                                values,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setFieldTouched,
                                errors,
                                status,
                                touched,
                                dirty,
                                initialValues,
                            } = props;

                            const updateBottles = (numAdd) => {
                                const newCount = numAdd + Number(values.bottleCount);
                                return setFieldValue('bottleCount', newCount);
                            };

                            const handleImgurUploadComplete = (url) => {
                                return setFieldValue('image_url', url);
                            };
                            const image_url = values.image_url;
                            const hasValidimageURL = image_url && image_url.startsWith('http');
                            const saverFnCreator = (saveStrategy) => () => {
                                setFieldValue('saveStrategy', saveStrategy, false);
                                handleSubmit();
                            };

                            return (
                                <Form>
                                    <Grid container direction="column">
                                        <Grid container direction="row" wrap="nowrap">
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                className={classes.upperForm}
                                            >
                                                <Grid item>
                                                    <Field type="hidden" name="id" />
                                                    <Field
                                                        component={TextField}
                                                        className={classes.formField}
                                                        autoFocus
                                                        name="name"
                                                        label="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        autoComplete="off"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <AutoComplete
                                                        className={classes.formField}
                                                        placeholder="Producer"
                                                        collectionName="producers"
                                                        name="producer"
                                                        value={values.producer}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        error={errors.producer}
                                                        touched={touched.producer}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <AutoComplete
                                                        className={classes.formField}
                                                        placeholder="Type"
                                                        collectionName="types"
                                                        name="type"
                                                        allowCreate={false}
                                                        value={values.type}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        error={errors.type}
                                                        touched={touched.type}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <AutoComplete
                                                        className={classes.formField}
                                                        placeholder="Varietal"
                                                        collectionName="varietals"
                                                        name="varietal"
                                                        value={values.varietal}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        error={errors.varietal}
                                                        touched={touched.varietal}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <AutoComplete
                                                        className={classes.formField}
                                                        placeholder="Tags"
                                                        collectionName="tags"
                                                        name="tags"
                                                        isMulti={true}
                                                        value={values.tags}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        error={errors.tags}
                                                        touched={touched.tags}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                justify="center"
                                                alignItems="center"
                                            >
                                                {hasValidimageURL && (
                                                    <WineImage url={image_url} size="large" />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.formRowExtraTop}>
                                            <Field
                                                component={TextField}
                                                className={classes.formFieldShort}
                                                // margin="dense"
                                                name="year"
                                                label="Year"
                                                variant="outlined"
                                                type="number"
                                                autoComplete="offForReal"
                                            />
                                            {/* chrome is autofilling year b/c of "birthyear" i guess apparently the hack above fixes it */}
                                            <Field
                                                component={TextField}
                                                className={classes.formFieldShort}
                                                // margin="dense"
                                                name="cost"
                                                label="Cost"
                                                variant="outlined"
                                                type="number"
                                                autoComplete="off"
                                            />
                                            <Field
                                                component={TextField}
                                                className={classes.formFieldShort}
                                                // margin="dense"
                                                name="retail"
                                                label="Retail"
                                                variant="outlined"
                                                type="number"
                                                autoComplete="off"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            wrap="nowrap"
                                            alignItems="center"
                                            spacing={2}
                                            className={classes.formRowExtraTop}
                                        >
                                            <Grid item xs>
                                                <Field
                                                    component={TextField}
                                                    className={classes.formField}
                                                    name="image_url"
                                                    label="Image URL"
                                                    variant="outlined"
                                                    fullWidth
                                                    autoComplete="off"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <ImgurUpload
                                                    onUploadComplete={handleImgurUploadComplete}
                                                />
                                            </Grid>
                                        </Grid>

                                        {isAddMode && (
                                            <Grid
                                                item
                                                container
                                                alignItems="center"
                                                className={classes.formRowExtraTop}
                                            >
                                                <Field
                                                    component={TextField}
                                                    className={classes.formFieldShort}
                                                    name="bottleCount"
                                                    label="Bottles"
                                                    variant="outlined"
                                                    type="number"
                                                    autoComplete="off"
                                                />
                                                <Button
                                                    variant="outlined"
                                                    className={classes.button}
                                                    onClick={() => updateBottles(1)}
                                                >
                                                    <AddIcon className={classes.lefttIcon} />
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    className={classes.button}
                                                    onClick={() => updateBottles(2)}
                                                >
                                                    <AddIcon className={classes.lefttIcon} /> 2
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    className={classes.button}
                                                    onClick={() => setFieldValue('bottleCount', 12)}
                                                >
                                                    12
                                                </Button>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <MarkDownField
                                                name="description"
                                                value={values.description}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                error={errors.description}
                                                touched={touched.description}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <hr />
                                            <Button
                                                disabled={isSubmitting}
                                                // type="submit"
                                                onClick={saverFnCreator('save')}
                                                color="primary"
                                                variant="outlined"
                                            >
                                                {buttonText}
                                            </Button>
                                            <Button
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                // type="submit"
                                                onClick={saverFnCreator('saveNew')}
                                                variant="outlined"
                                            >
                                                {buttonText} & New
                                            </Button>
                                            {isEditMode && (
                                                <Button
                                                    className={classes.button}
                                                    onClick={() =>
                                                        dispatch(
                                                            actions.setSelectedWineId({
                                                                selectedWineId: 0,
                                                            })
                                                        )
                                                    }
                                                    variant="outlined"
                                                >
                                                    Unselect
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    />
                    <CustomSnackbar
                        open={toastOpen}
                        onClose={handleClose}
                        variant="success"
                        message={toastMsg}
                    />
                </Paper>
            </Grid>

            <Grid item xs>
                {isEditMode && <InventoryAdjust formEdit={formEdit} />}
            </Grid>
        </Grid>
    );
}

function UpperCasingTextField(props) {
    const {
        form: { setFieldValue },
        field: { name },
    } = props;
    //console.log(props);
    const onChange = React.useCallback(
        (event) => {
            const { value } = event.target;
            setFieldValue(name, value ? value.toUpperCase() : '');
            //handleUrlChange(value);
        },
        [setFieldValue, name]
    );

    return <MuiTextField {...fieldToTextField(props)} onChange={onChange} />;
}
