import React, { useContext } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import { fieldToTextField, TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CustomSnackbar from '../util/CustomSnackbar';
import AddIcon from '@material-ui/icons/Add';

import { store, actions, selectors } from '../store/';
import { DataContext } from '../data/DataProvider';

const validationSchema = Yup.object().shape({
    bottlesAdjusted: Yup.number()
        .nullable()
        .integer('bottles must be an integer')
});

const initialValues = {
    id: 0,
    bottlesAdjusted: 0
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        maxWidth: 900,
        padding: 30
    },
    title: {
        marginBottom: 20
    },
    formField: {
        paddingTop: 8,
        margin: 4,
        marginRight: 10,
        //marginTop:8,
        marginBottom: 6
    },
    formFieldShort: {
        paddingTop: 8,
        marginBottom: 6,
        marginRight: 10,
        maxWidth: '10em'
    },
    formError: {
        color: 'red'
        //marginTop: 4
    },
    button: {
        color: theme.palette.secondary.dark,
        marginLeft: theme.spacing(1)
    },
    leftIcon: {
        marginRight: theme.spacing(1)
    },
    inventoryText: {
        marginBottom: 10,
        display: 'inline-block'
    }
}));

export default function InventoryAdjust({ formEdit }) {
    const dataContext = useContext(DataContext);
    const { dispatch, appState } = useContext(store);
    const classes = useStyles();
    const selectedWine = selectors.selectedWineSelector(appState);
    const { name, inventory } = selectedWine;
    const buttonText = 'Adjust';
    const toastMsg = `${buttonText} successful!`;
    const initialFormValues = { ...initialValues, ...selectedWine };
    const [toastOpen, setToastOpen] = React.useState(false);
    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    }

    return (
        <Paper className={classes.root}>
            <Typography variant="h6">Adjust Inventory for {name}</Typography>
            <Typography variant="overline" className={classes.inventoryText}>
                Current {inventory} bottles
            </Typography>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialFormValues}
                onSubmit={async (values, actions) => {
                    const payload = values;
                    const { setSubmitting, resetForm, setValues } = actions;
                    const updatedWine = await dataContext.adjustInventory(payload);
                    setSubmitting(false);
                    resetForm();
                    setToastOpen(true);
                    formEdit(updatedWine.id);
                }}
                render={props => {
                    const {
                        values,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setFieldTouched,
                        errors,
                        status,
                        touched
                    } = props;
                    const updateBottles = addValue =>
                        setFieldValue('bottlesAdjusted', addValue + Number(values.bottlesAdjusted));
                    return (
                        <Form>
                            <Grid container direction="column">
                                <Grid item container alignItems="center"></Grid>
                                <Grid item container alignItems="center">
                                    <Field type="hidden" name="id" />
                                    <Field
                                        component={TextField}
                                        className={classes.formFieldShort}
                                        name="bottlesAdjusted"
                                        label="Bottles"
                                        variant="outlined"
                                        type="number"
                                        autoComplete="off"
                                    />
                                    <Button
                                        variant="outlined"
                                        className={classes.button}
                                        onClick={() => updateBottles(1)}
                                    >
                                        <AddIcon className={classes.lefttIcon} />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className={classes.button}
                                        onClick={() => updateBottles(2)}
                                    >
                                        <AddIcon className={classes.lefttIcon} /> 2
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className={classes.button}
                                        onClick={() => updateBottles(12)}
                                    >
                                        <AddIcon className={classes.lefttIcon} /> 12
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <hr />
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        color="secondary"
                                        variant="outlined"
                                    >
                                        {buttonText}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            />
            <CustomSnackbar
                open={toastOpen}
                onClose={handleClose}
                variant="success"
                message={toastMsg}
            />
        </Paper>
    );
}
