import React, { useContext } from 'react';
import Link from '@material-ui/core/Link';
import { DataContext } from '../data/';
import { store, actions, selectors } from '../store/';
import MaterialTable from 'material-table';
import { ArrowUpward } from '@material-ui/icons';
import { BallotOutlined } from '@material-ui/icons';

const createLookup = (collection = []) =>
    collection.reduce((indexedById, o) => {
        indexedById[o.value] = o.label;
        return indexedById;
    }, {});

function TagsList(props) {
    const { rowData, rackView } = props;

    function handleClick() {
        rackView(rowData.id);
    }

    return (
        <Link href="javascript:void(0)" color="primary" onClick={handleClick}>
            {rowData.name}
        </Link>
    );
}

// todo: could clean this up some now
function WineViewLink(props) {
    const { rowData, rackView } = props;

    function handleClick() {
        rackView(rowData.id);
    }

    return (
        <Link href="javascript:void(0)" color="primary" onClick={handleClick}>
            {rowData.name}
        </Link>
    );
}

const getColumns = (appState, rowActions) => {
    const columns = [
        {
            title: 'Producer',
            field: 'fk_producer',
            lookup: createLookup(appState.producers || []),
            defaultSort: 'asc',
        },
        {
            title: 'Name',
            field: 'name',
            render: (rowData) => <WineViewLink rowData={rowData} rackView={rowActions.rackView} />,
        },
        {
            title: 'Varietal',
            field: 'fk_varietal',
            lookup: createLookup(appState.varietals || []),
        },
        {
            title: 'Type',
            field: 'fk_type',
            lookup: createLookup(appState.types || []),
        },
        { title: 'Year', field: 'year', type: 'numeric' },
        {
            title: 'Bottles',
            field: 'inventory',
            type: 'numeric',
            editable: 'never', // can potentially change to onAdd .. need to verify field names
        },
        {
            title: 'Tags',
            field: 'tagLabels',
            editable: 'never',
        },
    ];
    return columns;
};

const materialTableOptions = {
    actionsColumnIndex: -1,
    addRowPosition: 'first',
    emptyRowsWhenPaging: false,
    //paging:false,
    pageSize: 50,
    pageSizeOptions: [25, 50, 100, 200],
};

// NOTE this is DIFFERENT from the transform in WineEdit.js due to the naming of the fields etc
// not easily extractable
function transformFormFields(values) {
    const { cost, retail, fk_producer, fk_type, fk_varietal, ...rest } = values;
    const payload = {
        ...rest,
        fk_producer: fk_producer ? Number(fk_producer) : null,
        fk_type: fk_type ? Number(fk_type) : null,
        fk_varietal: fk_varietal ? Number(fk_varietal) : null,
        cost: cost ? Number(cost) : null,
        retail: retail ? Number(retail) : null,
    };
    return payload;
}

// todo add in selector. probably should do this elsewhere...
function addTagsLabels(wines = [], tags = []) {
    return wines.map((w) => {
        const { tag_ids } = w;
        const hasTags = tag_ids && tag_ids.length;

        let tagLabels = '';
        if (hasTags) {
            tagLabels = tag_ids
                .map((id) => {
                    let tagObj = tags.find((t) => Number(t.value) == id);
                    return tagObj.label;
                })
                .join(', ');
        }
        const tagsMappedWine = {
            ...w,
            tagLabels,
        };
        return tagsMappedWine;
    });
}

export default function WineList({ formEdit, rackView }) {
    const dataContext = useContext(DataContext);
    const { dispatch, appState } = useContext(store);

    //const classes = useStyles();
    //const winesFromState = appState.wines || [];
    const winesFromState = selectors.winesWithFilters(appState);
    const tags = appState.tags || [];
    const wines = addTagsLabels(winesFromState, tags);
    const rowActions = { formEdit, rackView };
    const columns = getColumns(appState, rowActions);

    return (
        <MaterialTable
            title="The Wine List"
            columns={columns}
            data={wines}
            actions={[
                {
                    icon: 'ballot_outlined',
                    tooltip: 'Advanced Edit',
                    onClick: (_, rowData) => formEdit(rowData.id),
                },
            ]}
            editable={{
                onRowAdd: (newData, oldData) =>
                    Promise.resolve().then(() => {
                        const data = transformFormFields(newData);
                        return dataContext.saveWine(data, oldData);
                    }),
                //onRowUpdate:(newData, oldData) => context.saveWine(newData, oldData),
                onRowUpdate: (newData, oldData) =>
                    Promise.resolve().then(() => {
                        const data = transformFormFields(newData);
                        return dataContext.saveWine(data, oldData);
                    }),
                onRowDelete: (oldData) =>
                    Promise.resolve().then(() => {
                        return dataContext.deleteWine(oldData.id);
                    }),
            }}
            options={materialTableOptions}
            icons={{
                SortArrow: React.forwardRef((props, ref) => (
                    <ArrowUpward {...props} fontSize="small" ref={ref} />
                )),
            }}
        />
    );
}

/*
const transformFormFieldsForFullSave = values => {
    // note here we want to always send tags b/c empty array means empty on server
    const { cost, retail, producer, type, varietal, ...rest } = values;
    const tags = values.tags || [];
    const payload = {
        ...rest,
        fk_producer: producer ? Number(producer.value) : null,
        fk_type: type ? Number(type.value) : null,
        fk_varietal: varietal ? Number(varietal.value) : null,
        tags: tags.map(t => Number(t.value)),
        cost: cost ? Number(cost) : null,
        retail: retail ? Number(retail) : null
    };
    return payload;
};
*/
