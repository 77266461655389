const NAME = 'TOPLEVEL';
export const SET_SELECTED_WINE = `${NAME}/SET_SELECTED_WINE`;
export const SET_SELECTED_WINE_ID = `${NAME}/SET_SELECTED_WINE_ID`;

export const CLEAR_SELECTED_WINE = `${NAME}/CLEAR_SELECTED_WINE`;
export const SET_EDIT_MODE = `${NAME}/SET_EDIT_MODE`;
export const SET_DRINK_MODE = `${NAME}/SET_DRINK_MODE`;
export const SET_RACK_ADD_MODE = `${NAME}/SET_RACK_ADD_MODE`;
export const SET_RACK_REMOVE_MODE = `${NAME}/SET_RACK_REMOVE_MODE`;

export const STAGE_BOTTLE_ADD_RACK = `${NAME}/STAGE_BOTTLE_ADD_RACK`;
export const UNSTAGE_BOTTLE_ADD_RACK = `${NAME}/UNSTAGE_BOTTLE_ADD_RACK`;

export const STAGE_BOTTLE_REMOVE_RACK = `${NAME}/STAGE_BOTTLE_REMOVE_RACK`;
export const UNSTAGE_BOTTLE_REMOVE_RACK = `${NAME}/UNSTAGE_BOTTLE_REMOVE_RACK`;

export const STAGE_BOTTLE_DRINK = `${NAME}/STAGE_BOTTLE_DRINK`;
export const UNSTAGE_BOTTLE_DRINK = `${NAME}/UNSTAGE_BOTTLE_DRINK`;

// can we get away with this?
export const SET_COLLECTION = `${NAME}/SET_COLLECTION`;
export const SET_COLLECTION_IS_LOADING = `${NAME}/SET_COLLECTION_IS_LOADING`;

export const SET_WINES = `${NAME}/SET_WINES`;
export const SET_WINES_IS_LOADING = `${NAME}/SET_WINES_IS_LOADING`;

export const SET_FILTER_VALUE = `${NAME}/SET_FILTER_VALUE`;

export const SET_USER = `${NAME}/SET_USER`;
export const UNSET_USER = `${NAME}/UNSET_USER`;
