import React, { Component, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, Chip, Grid, Paper, TextField } from '@material-ui/core';
import { store, actions, selectors } from '../store/';
import FilterDialog from '../util/FilterDialog';
import ToggleChip from '../util/ToggleChip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//import DateFilterChips from '../Filters/DateFilterChips'
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 8,
        marginBottom: 8,
    },
    menuWrapper: {
        margin: 10,
        padding: 10,
    },
}));
// var isthisOn = false;
function Filters(props) {
    const classes = useStyles();
    const { dispatch, appState } = useContext(store);
    const { filters } = appState;
    const { withInventoryOnly } = filters;

    const toggleFilter = (name) => {
        if (name === 'inventoryFilter') {
            const newValue = !withInventoryOnly;
            dispatch(actions.setFilterValue({ name: 'withInventoryOnly', value: newValue }));
        }
    };
    return (
        <Grid container className={classes.root} direction="row" spacing={1}>
            <Grid item>
                <ToggleChip
                    isOn={withInventoryOnly}
                    onLabel="Wines w/Inventory"
                    name="inventoryFilter"
                    offLabel="All Wines"
                    onToggle={toggleFilter}
                />
            </Grid>
            <Grid item>
                <TagMenu />
            </Grid>
            <Grid item>
                <YearMenu />
            </Grid>
            <Grid item>
                <PriceMenu />
            </Grid>
            <Grid item>
                <RackMenu />
            </Grid>
            <Grid item>
                <VarietalMenu />
            </Grid>
            <Grid item>
                <TypeMenu />
            </Grid>
        </Grid>
    );
}

const useStylesTagMenu = makeStyles((theme) => ({
    root: {
        padding: 8,
        maxWidth: 400,
    },
    range: {
        padding: 8,
        maxWidth: 250,
    },
    rangeField: {
        maxWidth: 100,
    },
    menuWrapper: {
        padding: 8,
    },
}));

function TagMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const [open, setOpen] = React.useState(false);
    const classes = useStylesTagMenu();
    const { dispatch, appState } = useContext(store);
    //const { tags } = appState;
    const selectedTags = selectors.selectedFilterTags(appState);
    const unSelectedTags = selectors.unSelectedFilterTags(appState);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const unselectTag = (value) => {
        const id = Number(value);
        const { tags = [] } = appState.filters;
        const newFilterTags = tags.filter((t) => t !== id);
        dispatch(actions.setFilterValue({ name: 'tags', value: newFilterTags }));
    };
    const selectTag = (value) => {
        const id = Number(value);
        const { tags = [] } = appState.filters;
        const newFilterTags = [...tags, id];
        dispatch(actions.setFilterValue({ name: 'tags', value: newFilterTags }));
    };
    return (
        <Grid container direction="row" spacing={1}>
            {selectedTags.map((tag, idx) => (
                <Grid item key={tag.value}>
                    <ToggleChip
                        isOn={true}
                        onLabel={tag.label}
                        name={tag.value}
                        offLabel={tag.label}
                        onToggle={unselectTag}
                    />
                </Grid>
            ))}
            <Grid item>
                <Chip
                    label="Tags"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    onClick={handleClick}
                />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    component={Paper}
                >
                    <Grid container direction="row" spacing={1} className={classes.root}>
                        {selectedTags.map((tag, idx) => (
                            <Grid item key={tag.value}>
                                <ToggleChip
                                    isOn={true}
                                    onLabel={tag.label}
                                    name={tag.value}
                                    offLabel={tag.label}
                                    onToggle={unselectTag}
                                />
                            </Grid>
                        ))}
                        {unSelectedTags.map((tag, idx) => (
                            <Grid item key={tag.value}>
                                <ToggleChip
                                    isOn={false}
                                    onLabel={tag.label}
                                    name={tag.value}
                                    offLabel={tag.label}
                                    onToggle={selectTag}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Menu>
            </Grid>
        </Grid>
    );
}

//todo refactor with TagMenu
function VarietalMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStylesTagMenu();
    const { dispatch, appState } = useContext(store);
    const selectedVarietals = selectors.selectedFilterVarietals(appState);
    const unSelectedVarietals = selectors.unSelectedFilterVarietals(appState);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const unselectVarietal = (value) => {
        const id = Number(value);
        const { varietals = [] } = appState.filters;
        const newFilterValue = varietals.filter((v) => v !== id);
        dispatch(actions.setFilterValue({ name: 'varietals', value: newFilterValue }));
    };
    const selectVarietal = (value) => {
        const id = Number(value);
        const { varietals = [] } = appState.filters;
        const newFilterValue = [...varietals, id];
        dispatch(actions.setFilterValue({ name: 'varietals', value: newFilterValue }));
    };

    return (
        <Grid container direction="row" spacing={1}>
            {selectedVarietals.map((varietal, idx) => (
                <Grid item key={varietal.value}>
                    <ToggleChip
                        isOn={true}
                        onLabel={varietal.label}
                        name={varietal.value}
                        offLabel={varietal.label}
                        onToggle={unselectVarietal}
                    />
                </Grid>
            ))}
            <Grid item>
                <Chip
                    label="Varietals"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    onClick={handleClick}
                />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    component={Paper}
                >
                    <Grid container direction="row" spacing={1} className={classes.root}>
                        {selectedVarietals.map((varietal, idx) => (
                            <Grid item key={varietal.value}>
                                <ToggleChip
                                    isOn={true}
                                    onLabel={varietal.label}
                                    name={varietal.value}
                                    offLabel={varietal.label}
                                    onToggle={unselectVarietal}
                                />
                            </Grid>
                        ))}
                        {unSelectedVarietals.map((varietal, idx) => (
                            <Grid item key={varietal.value}>
                                <ToggleChip
                                    isOn={false}
                                    onLabel={varietal.label}
                                    name={varietal.value}
                                    offLabel={varietal.label}
                                    onToggle={selectVarietal}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Menu>
            </Grid>
        </Grid>
    );
}

//todo refactor with TagMenu and VarietalMenu (maybe it is just collectionMenu?)
function TypeMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStylesTagMenu();
    const { dispatch, appState } = useContext(store);
    const selectedTypes = selectors.selectedFilterTypes(appState);
    const unSelectedTypes = selectors.unSelectedFilterTypes(appState);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const unselect = (value) => {
        const id = Number(value);
        const { types = [] } = appState.filters;
        const newFilterValue = types.filter((v) => v !== id);
        dispatch(actions.setFilterValue({ name: 'types', value: newFilterValue }));
    };
    const select = (value) => {
        const id = Number(value);
        const { types = [] } = appState.filters;
        const newFilterValue = [...types, id];
        dispatch(actions.setFilterValue({ name: 'types', value: newFilterValue }));
    };

    return (
        <Grid container direction="row" spacing={1}>
            {selectedTypes.map((type, idx) => (
                <Grid item key={type.value}>
                    <ToggleChip
                        isOn={true}
                        onLabel={type.label}
                        name={type.value}
                        offLabel={type.label}
                        onToggle={unselect}
                    />
                </Grid>
            ))}
            <Grid item>
                <Chip
                    label="Types"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    onClick={handleClick}
                />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    component={Paper}
                >
                    <Grid container direction="row" spacing={1} className={classes.root}>
                        {selectedTypes.map((type, idx) => (
                            <Grid item key={type.value}>
                                <ToggleChip
                                    isOn={true}
                                    onLabel={type.label}
                                    name={type.value}
                                    offLabel={type.label}
                                    onToggle={unselect}
                                />
                            </Grid>
                        ))}
                        {unSelectedTypes.map((type, idx) => (
                            <Grid item key={type.value}>
                                <ToggleChip
                                    isOn={false}
                                    onLabel={type.label}
                                    name={type.value}
                                    offLabel={type.label}
                                    onToggle={select}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Menu>
            </Grid>
        </Grid>
    );
}

function YearMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStylesTagMenu();
    const { dispatch, appState } = useContext(store);
    const { year = {} } = appState.filters;
    const { from, to } = year;
    const hasRange = from || to;
    //&rarr;
    const rangeStr = `${from ? from : 'any'} ` + '\u2192' + ` ${to ? to : 'any'}`;
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => {
        const from = Number(fromInputRef.current.value) || false;
        const to = Number(toInputRef.current.value) || false;
        const newYear = { from, to };
        dispatch(actions.setFilterValue({ name: 'year', value: newYear }));
        setAnchorEl(null);
    };
    const handleClearRange = () => {
        fromInputRef.current.value = '';
        toInputRef.current.value = '';
        dispatch(actions.setFilterValue({ name: 'year', value: { from: false, to: false } }));
    };
    const fromInputRef = React.useRef();
    const toInputRef = React.useRef();
    return (
        <Grid container direction="row" spacing={1}>
            <Grid item>
                {hasRange ? (
                    <Chip
                        label={rangeStr}
                        name="yearRange"
                        onClick={handleClick}
                        onDelete={handleClearRange}
                        variant="outlined"
                        color="primary"
                    />
                ) : (
                    <Chip
                        label="Years"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        color="secondary"
                        onClick={handleClick}
                    />
                )}
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    component={Paper}
                >
                    <Grid container direction="row" spacing={1} className={classes.range}>
                        <Grid item>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="fromYear"
                                label="From"
                                type="number"
                                className={classes.rangeField}
                                inputRef={fromInputRef}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="dense"
                                id="toYear"
                                label="To"
                                type="number"
                                className={classes.rangeField}
                                inputRef={toInputRef}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2} justify="flex-end">
                        <Grid item>
                            <Button onClick={handleClose}>OK</Button>
                        </Grid>
                    </Grid>
                </Menu>
            </Grid>
        </Grid>
    );
}

// very copy/paste with year,but i'm lazy right now
function PriceMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStylesTagMenu();
    const { dispatch, appState } = useContext(store);
    const { price = {} } = appState.filters;
    const { from, to } = price;
    const hasRange = from || to;
    const rangeStr = `${from ? from : 'any'} ` + '\u2192' + ` ${to ? to : 'any'}`;
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => {
        const from = Number(fromInputRef.current.value) || false;
        const to = Number(toInputRef.current.value) || false;
        const newYear = { from, to };
        dispatch(actions.setFilterValue({ name: 'price', value: newYear }));
        setAnchorEl(null);
    };
    const handleClearRange = () => {
        fromInputRef.current.value = '';
        toInputRef.current.value = '';
        dispatch(actions.setFilterValue({ name: 'price', value: { from: false, to: false } }));
    };
    const fromInputRef = React.useRef();
    const toInputRef = React.useRef();
    return (
        <Grid container direction="row" spacing={1}>
            <Grid item>
                {hasRange ? (
                    <Chip
                        label={rangeStr}
                        name="priceRange"
                        onClick={handleClick}
                        onDelete={handleClearRange}
                        variant="outlined"
                        color="primary"
                    />
                ) : (
                    <Chip
                        label="Price"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        color="secondary"
                        onClick={handleClick}
                    />
                )}
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    component={Paper}
                >
                    <Grid container direction="row" spacing={1} className={classes.range}>
                        <Grid item>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="fromPrice"
                                label="Min Price"
                                type="number"
                                className={classes.rangeField}
                                inputRef={fromInputRef}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="dense"
                                id="toPrice"
                                label="Max Price"
                                type="number"
                                className={classes.rangeField}
                                inputRef={toInputRef}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2} justify="flex-end">
                        <Grid item>
                            <Button onClick={handleClose}>OK</Button>
                        </Grid>
                    </Grid>
                </Menu>
            </Grid>
        </Grid>
    );
}

// very copy/paste with year and price,but i'm still lazy right now
function RackMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStylesTagMenu();
    const { dispatch, appState } = useContext(store);
    const { rack = {} } = appState.filters;
    const { inRack, outOfRack } = rack;
    const hasDetails = !(inRack && outOfRack);
    let rackStr = `${inRack ? 'In Rack' : ''} ` + `${outOfRack ? 'Not in Rack' : ''}`;
    if (!inRack && !outOfRack) {
        rackStr = 'No Rack Status Selected';
    }
    const handleClick = (event) => {
        return setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClear = () => {
        dispatch(
            actions.setFilterValue({ name: 'rack', value: { inRack: true, outOfRack: true } })
        );
    };
    const handleChange = (event) => {
        const newRack = { ...rack, [event.target.name]: event.target.checked };
        dispatch(actions.setFilterValue({ name: 'rack', value: newRack }));
    };
    return (
        <Grid container direction="row" spacing={2}>
            <Grid item>
                {hasDetails ? (
                    <Chip
                        label={rackStr}
                        name="rackSettings"
                        onClick={handleClick}
                        onDelete={handleClear}
                        variant="outlined"
                        color="primary"
                    />
                ) : (
                    <Chip
                        label="Rack"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        color="secondary"
                        onClick={handleClick}
                    />
                )}
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    component={Paper}
                >
                    <div className={classes.menuWrapper}>
                        <Grid container direction="row" spacing={1} className={classes.range}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="inRack"
                                            checked={inRack}
                                            onChange={handleChange}
                                        />
                                    }
                                    label="Show In Rack"
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="outOfRack"
                                            checked={outOfRack}
                                            onChange={handleChange}
                                        />
                                    }
                                    label="Show Not In Rack"
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={2} justify="flex-end">
                            <Grid item>
                                <Button onClick={handleClose}>OK</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Menu>
            </Grid>
        </Grid>
    );
}

export default Filters;
