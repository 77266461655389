import { BuiltInWineTypes, GenericImage } from './Settings';

export async function stall(stallTime = 3000) {
    await new Promise(resolve => setTimeout(resolve, stallTime));
}

export const capitalizeFirstLetterDropLast = string => {
    return string.charAt(0).toUpperCase() + string.slice(1, -1);
};

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formPluralizedCollectionName = clnName => `${clnName}s`;

export const formIsLoadingCollectionName = clnName => {
    const pluralizedClnName = formPluralizedCollectionName(clnName);
    return `isLoading${capitalizeFirstLetter(pluralizedClnName)}`;
};

export const formLoadMethodCollectionName = clnName => {
    const pluralizedClnName = formPluralizedCollectionName(clnName);
    const loadMethodName = `load${capitalizeFirstLetter(pluralizedClnName)}`;
    return loadMethodName;
};

export function getImageForWine(wine) {
    // if it has a specific one, return that
    if (wine.image_url) {
        return wine.image_url;
    }
    // else look for something to show
    // todo probably shouldn't have to work so hard for the type
    let wineType = wine.fk_type;
    if (!wineType && wine.type && wine.type.value) {
        // must have already been converted, but we need the int
        wineType = Number(wine.type.value);
    }
    if (wineType) {
        const builtInTypeImage = BuiltInWineTypes.get(wineType).image;
        const imageData = builtInTypeImage || GenericImage;
        return imageData;
    }
}
