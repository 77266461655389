import React, { useContext } from 'react';
import { DataContext } from '../data/';
import { store, selectors } from '../store/';

import { makeStyles } from '@material-ui/core/styles';
import Masonry from 'react-masonry-css';
import WineGridTile from './WineGridTile';

const useMasonryStyles = makeStyles(theme => ({
    masonryGrid: {
        display: 'flex',
        //marginLeft: -20 /* gutter size offset */,
        width: 'auto',
        maxWidth: 1200,
        margin: 'auto'
    },
    gridColumn: {
        paddingLeft: 20 /* gutter size */,
        backgroundClip: 'padding-box',
        // added these
        maxWidth: 1200,
        height: '100%',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    }
}));

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    400: 1
};

export default function MasonryGridList({ rackView }) {
    const classes = useMasonryStyles();
    const dataContext = useContext(DataContext);
    const { dispatch, appState } = useContext(store);
    const wines = selectors.winesWithFiltersAndMeta(appState);

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className={classes.masonryGrid}
            columnClassName={classes.gridColumn}
        >
            {wines.map((wine, idx) => (
                <WineGridTile rackView={rackView} wine={wine} key={wine.id} />
            ))}
        </Masonry>
    );
}
