import React, { useState, useContext } from 'react';
import { DataContext } from '../data/';
import { store, selectors } from '../store/';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import WineDetailsDialog from './WineDetailsDialog';

const useStyles = makeStyles((theme) => ({
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.4) 85%, rgba(0,0,0,.1) 100%)',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },

    titleBarTop: {
        // background:
        //     'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        //     'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
    },
    yearTitle: {
        paddingLeft: 28,
        paddingRight: 28,
        fontSize: '.8rem',
        paddingTop: 6,
        color: 'rgba(0, 0, 0, 0.7)',
    },
    gridListTile: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 16,
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: 20,
        border: '1px solid rgba(0,0,0,.3)',
        '&:hover, &$focusVisible': {
            border: '1px solid rgba(0,0,0,.8)',
            cursor: 'pointer',
        },
    },
    image: {
        left: '50%',
        height: '100%',
        position: 'relative',
        transform: 'translateX(-50%)',
        objectFit: 'cover',
        // maxWidth: 250,
        maxHeight: 300,
    },
    featureTall: {
        maxHeight: 400,
        //maxWidth: 450
    },
    featureShort: {
        maxHeight: 150,
        //maxWidth: 450
    },
}));

const useMasonryStyles = makeStyles((theme) => ({}));

// try component= div or paper for the GridListTile, try root class
//i think I just need to set the maxwidth and max height
// could use height to set some interesting differences, also see masonry demo
export default function WineGridTile({ wine, rackView }) {
    const classes = useStyles();
    //const tileClasses = useTileStyles();
    const { dispatch, appState } = useContext(store);
    const imageClasses = classNames({
        [classes.image]: true,
        [classes.featureTall]: wine.featureTall,
        [classes.featureShort]: wine.featureShort,
    });
    //const title = `${wine.name} - ${wine.inventory}`;
    const selectWine = () => rackView(wine.id);
    const showWineDetails = () => rackView(wine);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
    };

    return (
        <div className={classes.gridListTile} key={wine.id} onClick={selectWine}>
            <Grid container justify="space-between" className={classes.yearTitle}>
                <Grid item>{wine.year}</Grid>
                <Grid item>{wine.varietal && wine.varietal.label}</Grid>
            </Grid>
            <img src={wine.imageData} className={imageClasses} />
            <GridListTileBar
                title={wine.name}
                subtitle={<SubTitle wine={wine} />}
                actionIcon={
                    <IconButton
                        aria-label={`info about ${wine.name}`}
                        className={classes.icon}
                        onClick={handleClickOpen}
                    >
                        <InfoIcon />
                    </IconButton>
                }
                className={classes.titleBar}
            />
            <WineDetailsDialog wine={wine} handleClose={handleClose} open={open} />
        </div>
    );
}

function SubTitle({ wine }) {
    return (
        <Grid container justify="space-between">
            <Grid item>
                <span>by: {wine.producer && wine.producer.label}</span>
            </Grid>
            <Grid item>{wine.inventory} bottles</Grid>
        </Grid>
    );
}
