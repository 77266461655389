import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { store, actions, selectors } from '../store/';

import InventoryList from './InventoryList';

const bottlesViewStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.secondary.dark,
        marginRight: theme.spacing(1),
    },
    outerContainer: {
        alignSelf: 'stretch',
        backgroundColor: theme.palette.background.paper,
        // maxHeight: 400,
        width: '100%',
        marginTop: 8,
        marginBottom: 8,
        padding: 8,
    },
}));

export default function BottlesView() {
    const classes = bottlesViewStyles();
    const { dispatch, appState } = useContext(store);
    const availableBottles = selectors.selectedWineAvailableBottles(appState);
    const hasAvailablBottles = !!(availableBottles && availableBottles.length);
    return (
        hasAvailablBottles && (
            <Grid
                className={classes.outerContainer}
                container
                item
                spacing={1}
                direction="column"
                component={Paper}
            >
                <Grid item xs>
                    <InventoryList availableBottles={availableBottles} />
                </Grid>
            </Grid>
        )
    );
}
