import React, { Component } from 'react';

import Login from '../Login';

class Home extends Component {
    state = {};

    componentDidMount() {}

    render() {
        return <Login />;
    }
}

export default Home;
