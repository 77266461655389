import React, { useContext } from 'react';
import DrinkDialog from './DrinkDialog';
import BottleButton from './BottleButton';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { DataContext } from '../data/';
import { store, actions, selectors } from '../store/';

function InventoryList({ availableBottles }) {
    // const { dispatch, appState } = useContext(store);
    // const { drinkMode } = appState;
    const [drinkDialogOpen, setOpen] = React.useState(false);
    const [drinkDialogBtl, setSelectedBottle] = React.useState(null);

    function handleClose() {
        setOpen(false);
        setSelectedBottle(null);
    }

    function showDrinkDialog(btl) {
        setSelectedBottle(btl);
        setOpen(true);
    }

    return availableBottles ? (
        <React.Fragment>
            <Grid container spacing={1} direction="column">
                <Grid item>Unracked bottles:</Grid>
                <Grid container item spacing={1} direction="row">
                    {availableBottles.map((btl) => {
                        return (
                            <Grid item key={btl.id}>
                                <BottleButton onClick={() => showDrinkDialog(btl)} bottle={btl} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <DrinkDialog open={drinkDialogOpen} handleClose={handleClose} bottle={drinkDialogBtl} />
        </React.Fragment>
    ) : (
        <Grid container spacing={1} direction="row">
            <Grid item>No unracked bottles</Grid>
        </Grid>
    );
}

export default InventoryList;

// const handleClickOpen = () => {
//     setOpen(true);
// };
// function handleClick(btl) {
//     if (drinkMode) {
//         if (btl.stagedForDrinking) {
//             dispatch(actions.unstageBottleDrink({ bottleId: btl.id }));
//         } else {
//             dispatch(actions.stageBottleDrink({ bottleId: btl.id }));
//         }
//     } else {
//         setOpen(true);
//     }
// }
