import React from 'react';
import data from './wine-aroma-data';
import Sunburst from './Sunburst';

export default function Notes() {
    return (
        <div>
            <h1>Notes</h1>
            <div>
                <Sunburst
                    data={data}
                    width="800"
                    height="800"
                    count_member="size"
                    labelFunc={(node) => node.data.name}
                    _debug={false}
                />
            </div>
        </div>
    );
}
