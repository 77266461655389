import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import RackSlot from './RackSlot';

const useStyles2 = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 50,
        width: 50,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },
    rackRow: {
        //marginBottom: 2
        width: 'auto',
    },
    control: {
        padding: theme.spacing(2),
    },
    rowLabel: {
        width: 20,
    },
}));

export default function RackRow({ rowidx, row, ...rest }) {
    const classes = useStyles2();

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.rackRow}
            wrap="nowrap"
        >
            <Grid item className={classes.rowLabel}>
                {rowidx + 1}
            </Grid>
            <Grid item container direction="row" alignItems="center">
                {row.map((slotData, idx) => {
                    //todo classmap or whatever
                    const { bottleAtPosition } = slotData;
                    //let randColorIdx = getRandomInt(3);
                    //let colorclass = circleClasses[colors[randColorIdx]];
                    //const _classes = classNames(circleClasses.circle, colorclass);
                    const tmp = {
                        rowidx,
                        colidx: idx,
                    };
                    return <RackSlot key={idx} {...tmp} {...rest} {...slotData} />;
                })}
            </Grid>
        </Grid>
    );
}

/*
                    <Grid
                        key={idx}
                        item
                        container
                        justify="space-between"
                        alignItems="center"
                        className={classes.paper}
                    >
                                        </Grid>
*/
