// todo could get this from db and use Settings provider or else tag along with data or auth providers
// would need to do this if we have more than one database etc
// todo: add rack numbers
import whiteLight from '../images/white-light1.jpg';
import whiteSweet from '../images/white-sweet1.jpg';
import whiteFull from '../images/white-full1.png';
import redFull from '../images/red-full1.png';
import redMedium from '../images/red-med2.jpg';
import redLight from '../images/red-light1.jpg';
import rose from '../images/rose1.jpg';
import dessert from '../images/dessert2.jpg';
import sparkling from '../images/sparkling1.jpg';
import generic from '../images/generic.jpg';
// 9 shelves total and 1 and 3 and 8 are the short ones
export function getSettings() {
    return {
        racks: [
            {
                rackId: 1,
                rows: 18,
                cols: 14,
                shelves: 9,
                shortShelves: [1, 3, 8],
            },
            {
                rackId: 2,
                rows: 2,
                cols: 12,
            },
        ],
        // not used yet, but could do something as simple as this
        dbSchema: 'matt',
    };
}
/*
INSERT INTO type(id, name) VALUES (1,'Red - Light-Bodied Red');
INSERT INTO type(id, name) VALUES (2,'Red - Medium-Bodied Red');
INSERT INTO type(id, name) VALUES (3,'Red - Full-Bodied Red');
INSERT INTO type(id, name) VALUES (4,'Sparkling');
INSERT INTO type(id, name) VALUES (5,'Rosé');
INSERT INTO type(id, name) VALUES (6,'Dessert');
INSERT INTO type(id, name) VALUES (7,'White - Light-Bodied');
INSERT INTO type(id, name) VALUES (8,'White - Full-Bodied');
INSERT INTO type(id, name) VALUES (9,'White - Aromatic (sweet)');
*/
export const BuiltInWineTypes = new Map([
    [1, { name: 'Red - Light-Bodied Red', image: redLight }],
    [2, { name: 'Red - Medium-Bodied Red', image: redMedium }],
    [3, { name: 'Red - Full-Bodied Red', image: redFull }],
    [4, { name: 'Sparkling', image: sparkling }],
    [5, { name: 'Rosé', image: rose }],
    [6, { name: 'Dessert', image: dessert }],
    [7, { name: 'White - Light-Bodied', image: whiteLight }],
    [8, { name: 'White - Full-Bodied', image: whiteFull }],
    [9, { name: 'White - Aromatic (sweet)', image: whiteSweet }],
]);

export const GenericImage = generic;
