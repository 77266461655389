import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DataContext } from '../data/DataProvider';
import Grid from '@material-ui/core/Grid';
import { getSettings } from '../util/Settings';

const useStyles = makeStyles({
    table: {
        minWidth: 250,
    },
    paperRoot: {
        maxWidth: 4500,
    },
});

export default function InventorySummary() {
    const classes = useStyles();
    return (
        <Grid item container direction="row" spacing={4}>
            <Grid item>
                <InventoryInRack />
            </Grid>
            <Grid item>
                <InventoryActivityOverview />
            </Grid>
            <Grid item>
                <InventoryByVarietal />
            </Grid>
            <Grid item>
                <InventoryByProducer />
            </Grid>
        </Grid>
    );
}

export function InventoryInRack() {
    const settings = getSettings();
    const rackParams = settings.racks[0];
    const dataContext = useContext(DataContext);
    const [reportData, setData] = useState([]);
    const totalRackSlots = rackParams.rows * rackParams.cols;
    async function fetchData() {
        const invData = await dataContext.reportApi('inventoryStats');
        const availableSlots = totalRackSlots - invData.rackinventory;
        const invDataArray = [
            {
                name: 'Total Bottles',
                value: invData.totalinventory,
            },
            {
                name: 'Bottles in Rack',
                value: invData.rackinventory,
            },
            {
                name: 'Bottles in Boxes',
                value: invData.unrackedinventory,
            },
            {
                name: 'Total Rack Slots',
                value: totalRackSlots,
            },
            {
                name: 'Open Rack Slots',
                value: availableSlots,
            },
        ];
        setData(invDataArray);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const headers = [
        {
            label: 'Inventory Type',
            fieldName: 'name',
        },
        {
            label: 'Bottles',
            fieldName: 'value',
            align: 'right',
        },
    ];
    return <BasicReport data={reportData} headers={headers} />;
}

export function InventoryByVarietal() {
    const dataContext = useContext(DataContext);
    const [reportData, setData] = useState([]);

    async function fetchData() {
        const invDataArray = await dataContext.reportApi('varietalStats');
        setData(invDataArray);
    }

    useEffect(() => {
        fetchData();
    }, []);
    const headers = [
        {
            label: 'Varietal',
            fieldName: 'name',
        },
        {
            label: 'Bottles',
            fieldName: 'varietalcount',
            align: 'right',
        },
    ];
    return <BasicReport data={reportData} headers={headers} />;
}

export function InventoryActivityOverview() {
    const dataContext = useContext(DataContext);
    const [reportData, setData] = useState([]);

    async function fetchData() {
        const invDataArray = await dataContext.reportApi('inventoryActivityOverview');
        setData(invDataArray);
    }
    useEffect(() => {
        fetchData();
    }, []);

    const headers = [
        {
            label: 'Month',
            fieldName: 'date_str',
        },
        {
            label: 'Added',
            fieldName: 'added',
            align: 'right',
        },
        {
            label: 'Removed',
            fieldName: 'removed',
            align: 'right',
        },
        {
            label: 'Delta',
            fieldName: 'delta',
            align: 'right',
        },
    ];
    return <BasicReport data={reportData} headers={headers} />;
}

export function InventoryByProducer() {
    const dataContext = useContext(DataContext);
    const [reportData, setData] = useState([]);

    async function fetchData() {
        const invDataArray = await dataContext.reportApi('producerStats');
        setData(invDataArray);
    }

    //const [wine, setData] = useState({});
    useEffect(() => {
        fetchData();
    }, []);
    const headers = [
        {
            label: 'Producer',
            fieldName: 'name',
        },
        {
            label: 'Bottles',
            fieldName: 'producercount',
            align: 'right',
        },
    ];
    return <BasicReport data={reportData} headers={headers} />;
}

export function BasicReport({ headers, data }) {
    const classes = useStyles();
    return (
        <Paper className={classes.paperRoot}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {headers.map((header, idx) => (
                            <TableCell key={idx} align={header.align}>
                                {header.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, idx) => (
                        <TableRow key={idx}>
                            {headers.map((header, idx2) => {
                                const cellData = row[header.fieldName];
                                return (
                                    <TableCell key={idx2} align={header.align}>
                                        {cellData}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}
