import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleLogin } from 'react-google-login';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { store } from './store/';
import { AuthContext } from './auth/AuthProvider';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        display: 'flex',
        flexGrow: 1
    },
    title: {
        marginBottom: 4,
        fontSize: 18,
        paddingBottom: 8,
        borderBottom: '1px solid rgba(0,0,0,.1)'
    }
}));

export default function Login(props) {
    const classes = useStyles();
    const { appState } = useContext(store);
    const authContext = useContext(AuthContext);
    const { user } = appState;
    return (
        <div>
            <Grid
                container
                spacing={2}
                alignItems="center"
                justify="center"
                className={classes.root}
                direction="column"
            >
                <Grid item>
                    <Typography className={classes.title} variant="h5">
                        Welcome to Bacchus
                    </Typography>
                </Grid>
                <Grid item>
                    {!user.isAuthenticated ? (
                        <GoogleLogin
                            clientId="907308947044-vutduhh5pfl9ujboh61nl0vaf3pm2u5o.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={authContext.googleResponse}
                            onFailure={authContext.googleResponse}
                            render={renderProps => (
                                <Button
                                    color="primary"
                                    onClick={renderProps.onClick}
                                    variant="outlined"
                                >
                                    Login
                                </Button>
                            )}
                        />
                    ) : (
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justify="center"
                            direction="column"
                        >
                            <Grid item>Welcome back {user.email}</Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    onClick={authContext.logout}
                                    variant="outlined"
                                >
                                    Logout
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}

// class Login extends Component {

//     render() {
//         const { context, classes } = this.props;
//         //const { dispatch, appState } = useContext(store);
//         console.log(context);
//         const content =

//         return <div className="App">{content}</div>;
//     }
// }

//export default withStyles(styles, { withTheme: true })(withAuthContext(Login));
