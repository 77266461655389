export default {
    name: 'Aromas',
    children: [
        {
            name: 'Primary Aromas',
            children: [
                {
                    name: 'Flower',
                    children: [
                        { name: 'Iris', size: 1 },
                        { name: 'Peachy', size: 1 },
                        { name: 'Elderflower', size: 1 },
                        { name: 'Acacia', size: 1 },
                        { name: 'Lilac', size: 1 },
                        { name: 'Jasimine', size: 1 },
                        { name: 'Honeysuckle', size: 1 },
                        { name: 'Violet', size: 1 },
                        { name: 'Lavender', size: 1 },
                        { name: 'Rose', size: 1 },
                        { name: 'Potpourri', size: 1 },
                        { name: 'Hibiscus', size: 1 }
                    ]
                },
                {
                    name: 'Citrus',
                    children: [
                        { name: 'Lime', size: 1 },
                        { name: 'Lemon', size: 1 },
                        { name: 'Grapefruit', size: 1 },
                        { name: 'Orange', size: 1 },
                        { name: 'Marmalade', size: 1 }
                    ]
                },
                {
                    name: 'Tree Fruit',
                    children: [
                        { name: 'Quince', size: 1 },
                        { name: 'Apple', size: 1 },
                        { name: 'Pear', size: 1 },
                        { name: 'Nectarine', size: 1 },
                        { name: 'Peach', size: 1 },
                        { name: 'Apricot', size: 1 },
                        { name: 'Persimmon', size: 1 }
                    ]
                },
                {
                    name: 'Tropical Fruit',
                    children: [
                        { name: 'Pineapple', size: 1 },
                        { name: 'Mango', size: 1 },
                        { name: 'Guava', size: 1 },
                        { name: 'Kiwi', size: 1 },
                        { name: 'Lychee', size: 1 },
                        { name: 'Bubblegum', size: 1 }
                    ]
                },
                {
                    name: 'Red Fruit',
                    children: [
                        { name: 'Cranberry', size: 1 },
                        { name: 'Red Plum', size: 1 },
                        { name: 'Pomegranate', size: 1 },
                        { name: 'Sour Cherry', size: 1 },
                        { name: 'Strawberry', size: 1 },
                        { name: 'Cherry', size: 1 },
                        { name: 'Raspberry', size: 1 }
                    ]
                },
                {
                    name: 'Black Fruit',
                    children: [
                        { name: 'Boysenberry', size: 1 },
                        { name: 'Black Currant', size: 1 },
                        { name: 'Black Cherry', size: 1 },
                        { name: 'Plum', size: 1 },
                        { name: 'Blackberry', size: 1 },
                        { name: 'Blueberry', size: 1 },
                        { name: 'Olive', size: 1 }
                    ]
                },
                {
                    name: 'Dried Fruit',
                    children: [
                        { name: 'Raisin', size: 1 },
                        { name: 'Fig', size: 1 },
                        { name: 'Date', size: 1 },
                        { name: 'Fruitcake', size: 1 }
                    ]
                },
                {
                    name: 'Noble Rot',
                    children: [
                        { name: 'Beeswax', size: 1 },
                        { name: 'Ginger', size: 1 },
                        { name: 'Saffron', size: 1 }
                    ]
                },
                {
                    name: 'Spice',
                    children: [
                        { name: 'White Pepper', size: 1 },
                        { name: 'Red Pepper', size: 1 },
                        { name: 'Black Pepper', size: 1 },
                        { name: 'Cinnamon', size: 1 },
                        { name: 'Anise', size: 1 },
                        { name: 'Asian 5-Spice', size: 1 },
                        { name: 'Fennel', size: 1 },
                        { name: 'Eucalyptus', size: 1 },
                        { name: 'Mint', size: 1 },
                        { name: 'Thyme', size: 1 }
                    ]
                },
                {
                    name: 'Vegetable',
                    children: [
                        { name: 'Black Tea', size: 1 },
                        { name: 'Sun Dried Tomato', size: 1 },
                        { name: 'Tomato', size: 1 },
                        { name: 'Green Almond', size: 1 },
                        { name: 'Jalapeño', size: 1 },
                        { name: 'Bell Pepper', size: 1 },
                        { name: 'Gooseberry', size: 1 },
                        { name: 'Tomato Leaf', size: 1 },
                        { name: 'Grass', size: 1 }
                    ]
                },
                {
                    name: 'Earth',
                    children: [
                        { name: 'Petroleum', size: 1 },
                        { name: 'Volcanic Rocks', size: 1 },
                        { name: 'Red Beet', size: 1 },
                        { name: 'Potting Soil', size: 1 },
                        { name: 'Wet Gravel', size: 1 },
                        { name: 'Slate', size: 1 },
                        { name: 'Clay Pot', size: 1 }
                    ]
                }
            ]
        },
        {
            name: 'Faults',
            children: [
                {
                    name: 'Volatile Acidity',
                    children: [
                        { name: '', size: 1 },
                        { name: '', size: 1 }
                    ]
                },
                {
                    name: 'Cooked',
                    children: [
                        { name: 'Stewed fruit', size: 1 },
                        { name: 'Toffee', size: 1 }
                    ]
                },
                {
                    name: 'Bretanomyces',
                    children: [
                        { name: 'Horse Manure', size: 1 },
                        { name: 'Sweaty Leather', size: 1 },
                        { name: 'Band-Aid', size: 1 },
                        { name: 'Black Cardomom', size: 1 }
                    ]
                },
                {
                    name: 'Sulfides & Mercaptans',
                    children: [
                        { name: 'Cat Pee', size: 1 },
                        { name: 'Onion', size: 1 },
                        { name: 'Garlic', size: 1 },
                        { name: 'Match Box', size: 1 },
                        { name: 'Burnt Rubber', size: 1 },
                        { name: 'Boiled Eggs', size: 1 },
                        { name: 'Cured Meat', size: 1 }
                    ]
                },
                {
                    name: 'TCA (Corked)',
                    children: [
                        { name: 'Wet Dog', size: 1 },
                        { name: 'Musty Cardboard', size: 1 }
                    ]
                }
            ]
        },
        {
            name: 'Secondary',
            children: [
                {
                    name: 'Microbial',
                    children: [
                        { name: 'Mushroom', size: 1 },
                        { name: 'Truffle', size: 1 },
                        { name: 'Lager', size: 1 },
                        { name: 'Sourdough', size: 1 },
                        { name: 'Cream', size: 1 },
                        { name: 'Butter', size: 1 }
                    ]
                }
            ]
        },
        {
            name: 'Tertiary',
            children: [
                {
                    name: 'General Aging',
                    children: [
                        { name: 'Leather', size: 1 },
                        { name: 'Cocoa', size: 1 },
                        { name: 'Coffee', size: 1 },
                        { name: 'Tobacco', size: 1 },
                        { name: 'Nuts', size: 1 },
                        { name: 'Dried Fruit', size: 1 }
                    ]
                },
                {
                    name: 'Oak Aging',
                    children: [
                        { name: 'Dill', size: 1 },
                        { name: 'Smoke', size: 1 },
                        { name: 'Cigar Box', size: 1 },
                        { name: 'Baking Spices', size: 1 },
                        { name: 'Coconut', size: 1 },
                        { name: 'Vanilla', size: 1 }
                    ]
                }
            ]
        }
    ]
};
