import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import WineImage from './WineImage';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: 4,
        fontSize: 18,
        whiteSpace: 'nowrap',
        overflow: 'ellipsis',
        //paddingBottom: 8
    },
    titleContainer: {
        borderBottom: '1px solid rgba(0,0,0,.1)',
    },
    cardLabel: {
        fontSize: '0.9rem',
    },
    detailsPanelRoot: {
        border: '1px solid rgba(0, 0, 0,.1)',
        boxShadow: 'none',
        width: '100%',
    },
    detailsContainer: {
        marginTop: 8,
    },
    ttVariantTitle: {
        fontSize: 14,
        fontWeight: 700,
    },
    bottleDetails: {
        marginTop: 8,
    },
}));

// todo make inner details (and maybe heading summary)
// export those and use those in WineView and to wineview have a different look than this
// just just this for tooltip and for info button

export default function WineDetails({ wine, variant, children }) {
    const classes = useStyles();
    const { name, producer, varietal, year, inventory, description, bottles } = wine;
    const hasSelectedWine = wine && wine.id > 0;
    const inventoryString = inventory ? `${inventory} bottles` : 'no inventory';
    const toolTipMode = variant === 'tooltip';
    const gridListPopupMode = variant === 'gridlistInfo';
    const imageSize = toolTipMode ? 'small' : 'medium';

    const titleClasses = classNames({
        [classes.title]: true,
        [classes.ttVariantTitle]: toolTipMode,
    });

    const showDescription = !toolTipMode && description && description.trim() !== '';
    const showTextRackDetails = gridListPopupMode;
    const rackLocations =
        showTextRackDetails &&
        bottles &&
        bottles
            .filter((b) => b.rowidx !== null && b.colidx !== null)
            .map((b) => `r${b.rowidx},c${b.colidx}`)
            .join(' | ');

    const outOfRackCount =
        showTextRackDetails &&
        bottles &&
        bottles.filter((b) => b.rowidx === null && b.colidx === null).length;

    return (
        hasSelectedWine && (
            <React.Fragment>
                <Grid
                    container
                    item
                    spacing={1}
                    justify="space-between"
                    direction="row"
                    wrap="nowrap"
                >
                    <Grid container item direction="column" spacing={1}>
                        <Grid
                            item
                            container
                            direction="row"
                            justify="space-between"
                            className={classes.titleContainer}
                        >
                            <Grid item>
                                <Typography className={titleClasses} variant="h5">
                                    {year} {producer.label} {name} {varietal && varietal.label}
                                </Typography>
                                <Typography variant="subtitle1">{inventoryString}</Typography>
                            </Grid>
                        </Grid>
                        <WineSubDetails wine={wine} />
                        {!!outOfRackCount && (
                            <Grid item className={classes.bottleDetails}>
                                Bottles out of rack: {outOfRackCount}
                            </Grid>
                        )}
                        {!!rackLocations && (
                            <Grid item className={classes.bottleDetails}>
                                In Rack locations: {rackLocations}
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <WineImage size={imageSize} wine={wine} />
                    </Grid>
                </Grid>
                {showDescription && (
                    <Grid container item className={classes.detailsContainer} xs="auto">
                        <DescriptionPanel description={description} classes={classes} />
                    </Grid>
                )}
            </React.Fragment>
        )
    );
}

function TagList({ tags }) {
    tags = tags || [];
    return tags.map((tag, idx) => (
        <Grid item key={tag.value}>
            <Chip label={tag.label} color="secondary" variant="outlined" />
        </Grid>
    ));
}

export function DescriptionPanel({ description }) {
    const handleExpansionClick = (e) => {
        e.stopPropagation();
    };
    const classes = useStyles();
    return (
        <ExpansionPanel className={classes.detailsPanelRoot} onClick={handleExpansionClick}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Description/Notes</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <ReactMarkdown source={description} />
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}

export function WineSubDetails({ wine }) {
    const { type, cost, retail, tags } = wine;
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container direction="row" item spacing={1} justify="space-between">
                <Grid item container direction="column" spacing={0}>
                    <Grid item>
                        <Typography variant="subtitle1" className={classes.cardLabel}>
                            Style
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="secondary">
                            {type && type.label}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={0}>
                    <Grid item>
                        <Typography variant="subtitle1" className={classes.cardLabel}>
                            Cost/Retail
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="secondary">
                            {cost} / {retail}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2} wrap="wrap">
                <TagList tags={tags} />
            </Grid>
        </React.Fragment>
    );
}
