//import React, { Component } from 'react';

import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactMde from 'react-mde';
import Link from '@material-ui/core/Link';
import ReactMarkdown from 'react-markdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import 'react-tabs/style/react-tabs.css';

const useStyles = makeStyles(theme => ({
    markdownEditField: {
        width: '100%'
    },
    linkContainer: {
        textAlign: 'right'
    }
}));

export default function MarkDownField({
    value,
    className,
    onChange,
    onBlur,
    error,
    touched,
    defaultIndex = 0,
    name
}) {
    // note TextField multiline also is a a textArea and might work for the editor if this gets out of hand
    //    const classes = useStyles();
    function handleChange(value) {
        onChange(name, value);
    }

    function handleBlur() {
        onBlur(name, true);
    }

    const [selectedTab, setSelectedTab] = useState('write');

    return (
        <div className={className}>
            <Link
                href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                target="_blank"
            >
                Markdown Cheatsheet
            </Link>
            <ReactMde
                value={value}
                onChange={handleChange}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={markdown =>
                    Promise.resolve(<ReactMarkdown source={markdown} />)
                }
            />

            {!!error && touched && <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>}
        </div>
    );
}

/*
            <Tabs defaultIndex={defaultIndex}>
                <TabList>
                    <Tab>Edit</Tab>
                    <Tab>Preview</Tab>
                </TabList>

                <TabPanel>
                    <div className={classes.linkContainer}>
                        <Link
                            href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                            target="_blank"
                        >
                            Markdown Cheatsheet
                        </Link>
                    </div>
                </TabPanel>
                <TabPanel>
                    <ReactMarkdown source={value} />
                </TabPanel>
            </Tabs>
>

                    <TextareaAutosize
                        className={classes.markdownEditField}
                        rows={6}
                        placeholder="You can add a markdown here."
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

*/
