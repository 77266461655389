import React, { createContext, useReducer } from 'react';
import * as t from './actionTypes';
import * as selectors from './selectors';
import { pluralizedCollectionNames, isLoadingCollectionNames } from './constants';
import { formIsLoadingCollectionName, formPluralizedCollectionName } from '../util/helpers';

//todo: change, this will set it true for dev, need to fix it properly
const defaultAuthState = process.env.NODE_ENV === 'development';

const INITIAL_STATE = {
    selectedWineId: 0,
    user: {
        isAuthenticated: defaultAuthState,
    },
    wineEditMode: false,
    stagedBottlesAddRack: [],
    stagedBottlesRemoveRack: [],
    stagedBottlesToDrink: [],
    rackAddMode: false,
    rackRemoveMode: false,
    drinkMode: false,
    filters: {
        withInventoryOnly: true,
        tags: [],
        varietals: [],
        types: [],
        price: {
            from: false,
            to: false,
        },
        year: {
            from: false,
            to: false,
        },
        rack: {
            inRack: true,
            outOfRack: true,
        },
    },
};

isLoadingCollectionNames.forEach((isLoadingClnName) => {
    INITIAL_STATE[isLoadingClnName] = false;
});

pluralizedCollectionNames.forEach((pluralizedClnName) => {
    INITIAL_STATE[pluralizedClnName] = null;
});

const store = createContext(INITIAL_STATE);

const { Provider } = store;

const StateProvider = ({ children }) => {
    const [appState, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case t.SET_SELECTED_WINE_ID: {
                return {
                    ...state,
                    selectedWineId: action.payload.selectedWineId,
                };
            }
            case t.SET_EDIT_MODE:
                return {
                    ...state,
                    wineEditMode: action.payload.wineEditMode,
                };
            case t.STAGE_BOTTLE_ADD_RACK: {
                const { rowidx, colidx } = action.payload;
                const availableBottles = selectors.selectedWineAvailableBottles(state);
                const bottleToPlace = {
                    ...availableBottles[0], // doesn't really matter which one, just need to pick one, so pick first available
                    rowidx,
                    colidx,
                };
                const stagedBottlesAddRack = state.stagedBottlesAddRack.slice(0) || [];
                stagedBottlesAddRack.push(bottleToPlace);
                return {
                    ...state,
                    stagedBottlesAddRack,
                };
            }
            case t.UNSTAGE_BOTTLE_ADD_RACK: {
                const { rowidx, colidx } = action.payload;
                const stagedBottlesAddRack = state.stagedBottlesAddRack;

                const bottleToUnPlace = stagedBottlesAddRack.find(
                    (b) => b.rowidx == rowidx && b.colidx == colidx
                );
                const newStaged = stagedBottlesAddRack.filter((b) => b.id != bottleToUnPlace.id);
                return {
                    ...state,
                    stagedBottlesAddRack: newStaged,
                };
            }
            case t.STAGE_BOTTLE_DRINK: {
                //these might all be able to use bottleId and then this could be cleaner
                const { bottleId, fromRack, rowidx, colidx } = action.payload;
                let bottleToDrink;
                if (fromRack) {
                    const bottlesInRack = selectors.bottlesInRack(state);
                    bottleToDrink = bottlesInRack.find(
                        (b) => b.rowidx === rowidx && b.colidx === colidx
                    );
                } else {
                    const availableBottles = selectors.selectedWineAvailableBottles(state);
                    bottleToDrink = availableBottles.find((b) => b.id === bottleId);
                }

                const stagedBottlesToDrink = state.stagedBottlesToDrink.slice(0) || [];
                if (bottleToDrink) {
                    stagedBottlesToDrink.push(bottleToDrink);
                }
                return {
                    ...state,
                    stagedBottlesToDrink,
                };
            }
            case t.UNSTAGE_BOTTLE_DRINK: {
                const { bottleId, fromRack, rowidx, colidx } = action.payload;
                const stagedBottlesToDrink = state.stagedBottlesToDrink;
                let bottleToUnStage;
                if (fromRack) {
                    bottleToUnStage = stagedBottlesToDrink.find(
                        (b) => b.rowidx == rowidx && b.colidx == colidx
                    );
                } else {
                    bottleToUnStage = stagedBottlesToDrink.find((b) => b.id === bottleId);
                }
                const newStaged = stagedBottlesToDrink.filter((b) => b.id != bottleToUnStage.id);
                return {
                    ...state,
                    stagedBottlesToDrink: newStaged,
                };
            }

            case t.STAGE_BOTTLE_REMOVE_RACK: {
                //todo handle staging un-racked to drink
                const { rowidx, colidx } = action.payload;
                const bottlesInRack = selectors.bottlesInRack(state);
                const bottleToRemove = bottlesInRack.find(
                    (b) => b.rowidx === rowidx && b.colidx === colidx
                );
                const stagedBottlesRemoveRack = state.stagedBottlesRemoveRack.slice(0) || [];
                stagedBottlesRemoveRack.push(bottleToRemove);
                return {
                    ...state,
                    stagedBottlesRemoveRack,
                };
            }
            case t.UNSTAGE_BOTTLE_REMOVE_RACK: {
                const { rowidx, colidx } = action.payload;
                const stagedBottlesRemoveRack = state.stagedBottlesRemoveRack;
                const bottleToUnStage = stagedBottlesRemoveRack.find(
                    (b) => b.rowidx == rowidx && b.colidx == colidx
                );
                const newStaged = stagedBottlesRemoveRack.filter((b) => b.id != bottleToUnStage.id);
                return {
                    ...state,
                    stagedBottlesRemoveRack: newStaged,
                };
            }
            case t.SET_RACK_ADD_MODE: {
                const { rackAddMode } = action.payload;
                return {
                    ...state,
                    rackAddMode,
                    stagedBottlesAddRack: [],
                };
            }
            case t.SET_RACK_REMOVE_MODE: {
                const { rackRemoveMode } = action.payload;
                return {
                    ...state,
                    rackRemoveMode,
                    stagedBottlesRemoveRack: [],
                };
            }
            case t.SET_DRINK_MODE: {
                const { drinkMode } = action.payload;
                return {
                    ...state,
                    drinkMode,
                    stagedBottlesToDrink: [],
                };
            }
            case t.SET_COLLECTION: {
                const { collectionName, collection } = action.payload;
                const isLoadingClnName = formIsLoadingCollectionName(collectionName.slice(0, -1));
                return {
                    ...state,
                    [collectionName]: collection,
                    [isLoadingClnName]: false,
                };
            }
            case t.SET_COLLECTION_IS_LOADING: {
                const { collectionName, isLoading } = action.payload;
                const isLoadingClnName = formIsLoadingCollectionName(collectionName);
                return {
                    ...state,
                    [isLoadingClnName]: isLoading,
                };
            }
            case t.SET_FILTER_VALUE: {
                const { name, value } = action.payload;
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        [name]: value,
                    },
                };
            }
            case t.SET_USER: {
                const { data } = action.payload;
                return {
                    ...state,
                    user: {
                        isAuthenticated: true,
                        ...data,
                    },
                };
            }
            case t.UNSET_USER: {
                return {
                    ...state,
                    user: {
                        isAuthenticated: false,
                    },
                };
            }
            default:
                throw new Error();
        }
    }, INITIAL_STATE);

    return <Provider value={{ appState, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
