import { makeActionCreator } from './helpers';
import * as t from './actionTypes';

export const setSelectedWineId = makeActionCreator(t.SET_SELECTED_WINE_ID, 'selectedWineId');
export const setEditMode = makeActionCreator(t.SET_EDIT_MODE, 'wineEditMode');

export const stageBottleAddRack = makeActionCreator(t.STAGE_BOTTLE_ADD_RACK, 'colidx', 'rowidx');
export const unstageBottleAddRack = makeActionCreator(
    t.UNSTAGE_BOTTLE_ADD_RACK,
    'colidx',
    'rowidx'
);

export const stageBottleRemoveRack = makeActionCreator(
    t.STAGE_BOTTLE_REMOVE_RACK,
    'colidx',
    'rowidx'
);
export const unstageBottleRemoveRack = makeActionCreator(
    t.UNSTAGE_BOTTLE_REMOVE_RACK,
    'colidx',
    'rowidx'
);

export const stageBottleDrink = makeActionCreator(
    t.STAGE_BOTTLE_DRINK,
    'colidx',
    'rowidx',
    'fromRack',
    'bottleId'
);
export const unstageBottleDrink = makeActionCreator(
    t.UNSTAGE_BOTTLE_DRINK,
    'colidx',
    'rowidx',
    'fromRack',
    'bottleId'
);

export const setDrinkMode = makeActionCreator(t.SET_DRINK_MODE, 'drinkMode');
export const setRackAddMode = makeActionCreator(t.SET_RACK_ADD_MODE, 'rackAddMode');
export const setRackRemoveMode = makeActionCreator(t.SET_RACK_REMOVE_MODE, 'rackRemoveMode');

export const setCollection = makeActionCreator(t.SET_COLLECTION, 'collectionName', 'collection');
export const setCollectionIsLoading = makeActionCreator(
    t.SET_COLLECTION_IS_LOADING,
    'collectionName',
    'isLoading'
);

export const setFilterValue = makeActionCreator(t.SET_FILTER_VALUE, 'name', 'value');

export const setUser = makeActionCreator(t.SET_USER, 'data');
export const unsetUser = makeActionCreator(t.UNSET_USER);
