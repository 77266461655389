import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { getImageForWine } from '../util/helpers';

const useStyles = makeStyles((theme) => ({
    base: {
        display: 'block',
        width: 'auto',
        height: 'auto',
    },
    large: {
        maxWidth: 250,
        maxHeight: 250,
    },
    medium: {
        maxWidth: 175,
        maxHeight: 175,
    },
    small: {
        maxWidth: 100,
        maxHeight: 100,
    },
    logoSquare: {
        width: 100,
        height: 100,
        border: '1px solid rgba(0,0,0,.3)',
        borderRadius: 8,
        objectFit: 'contain',
        padding: 8,
    },
}));

export default function WineImage({ wine, size, variant, url }) {
    const classes = useStyles();
    size = size || 'medium';
    variant = variant || '';
    url = url || getImageForWine(wine);
    const imageClasses = classNames({
        [classes.base]: true,
        [classes.large]: size === 'large',
        [classes.medium]: size === 'medium',
        [classes.small]: size === 'small',
        [classes.logoSquare]: variant === 'logoSquare',
    });
    return url && <img className={imageClasses} src={url} />;
}
