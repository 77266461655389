import { createSelector } from 'reselect';
import { isLoadingCollectionNames } from './constants';
import { getImageForWine } from '../util/helpers';
import shuffle from 'lodash/shuffle';

function transformData({
    wine,
    producers = [],
    varietals = [],
    types = [],
    tags = [],
    defaultValues,
}) {
    if (wine && wine.id) {
        let {
            id,
            name,
            fk_producer,
            fk_type,
            fk_varietal,
            year,
            tag_ids,
            bottles,
            inventory,
            cost,
            retail,
            image_url,
            description,
        } = wine;
        let producer = producers.find((p) => fk_producer == Number(p.value));
        let varietal = varietals.find((v) => fk_varietal == Number(v.value));
        let type = types.find((t) => fk_type == Number(t.value));

        let mappedTags;
        if (tag_ids && tag_ids.length) {
            mappedTags = tag_ids.map((tid) => tags.find((t) => tid == Number(t.value)));
        }

        return {
            ...defaultValues,
            id,
            name,
            producer,
            type,
            varietal,
            year,
            inventory,
            tags: mappedTags,
            bottles,
            cost: cost || '',
            retail: retail || '',
            image_url: image_url || '',
            description: description || '',
        };
    } else {
        return defaultValues;
    }
}

const defaultInitialValues = {
    id: 0,
    name: '',
    producer: '',
    type: '',
    varietal: '',
    year: '',
    cost: '',
    retail: '',
    tags: [],
    image_url: '',
    description: '',
};

export const selectedWineSelector = createSelector(
    (appState) => appState.selectedWineId,
    (appState) => appState.wines,
    (appState) => appState.producers,
    (appState) => appState.varietals,
    (appState) => appState.types,
    (appState) => appState.tags,
    (selectedWineId, wines, producers, varietals, types, tags) => {
        let wine = {};
        if (selectedWineId && wines) {
            wine = wines.find((w) => w.id == selectedWineId);
        }
        return transformData({
            wine,
            producers,
            varietals,
            types,
            tags,
            defaultValues: defaultInitialValues,
        });
    }
);

export const collectionsLoading = createSelector(
    (appState) => appState,
    (state) => {
        return isLoadingCollectionNames.some(
            (isLoadingCollectionName) => state[isLoadingCollectionName]
        );
    }
);

export const selectedWineAvailableBottles = createSelector(
    selectedWineSelector,
    (appState) => appState.stagedBottlesAddRack,
    (appState) => appState.stagedBottlesToDrink,
    (selectedWine, stagedBottlesAddRack, stagedBottlesToDrink) => {
        if (selectedWine && selectedWine.bottles && selectedWine.bottles.length) {
            const { bottles, ...wineDetails } = selectedWine;
            const unPlacedBottles = getUnplacedBottles(bottles);
            if (stagedBottlesAddRack && stagedBottlesAddRack.length) {
                return unPlacedBottles.filter(
                    (bottle) => !stagedBottlesAddRack.find((pb) => pb.id === bottle.id)
                );
            }
            // add in wineDetails to match the way the rack does it
            return unPlacedBottles.map((b) => {
                b.wineDetails = wineDetails;
                if (stagedBottlesToDrink && stagedBottlesToDrink.length) {
                    b.stagedForDrinking = stagedBottlesToDrink.some((sb) => sb.id === b.id);
                } else {
                    b.stagedForDrinking = false;
                }
                b.isOfSelectedWine = true;
                return b;
            });
        }
        return [];
    }
);

function getUnplacedBottles(bottles) {
    bottles = bottles || [];
    const unPlacedBottles = bottles && bottles.filter((b) => b.colidx == null || b.rowidx == null);
    return unPlacedBottles;
}

// take all wines can figure out the x/y posistions
// todo selector
// function getBottlesInRack(wines) {
//     wines = wines || [];
//     return wines.reduce((rackdata, wine) => {
//         const { bottles, ...wineDetails } = wine;
//         if (bottles && bottles.length) {
//             const bottlesInRack = bottles.filter(b => b.colidx != null && b.rowidx != null);
//             const bottlesWithData = bottlesInRack.map(b => {
//                 b.wineDetails = wineDetails;
//                 // todo: hack for now
//                 b.rackId = 1;
//                 return b;
//             });
//             rackdata.push(...bottlesWithData);
//         }
//         return rackdata;
//     }, []);
// }

export const bottlesInRack = createSelector(
    (appState) => appState.wines,
    (appState) => appState.producers,
    (appState) => appState.varietals,
    (appState) => appState.types,
    (appState) => appState.tags,
    (wines, producers, varietals, types, tags) => {
        wines = wines || [];
        return wines.reduce((rackdata, wine) => {
            const wineTransformed = transformData({
                wine,
                producers,
                varietals,
                types,
                tags,
                defaultValues: defaultInitialValues,
            });
            const { bottles, ...wineDetails } = wineTransformed;

            if (bottles && bottles.length) {
                const bottlesInRack = bottles.filter((b) => b.colidx != null && b.rowidx != null);
                const bottlesWithData = bottlesInRack.map((b) => {
                    b.wineDetails = wineDetails;
                    // todo: hack for now
                    b.rackId = 1;
                    return b;
                });
                rackdata.push(...bottlesWithData);
            }

            return rackdata;
        }, []);
    }
);

export const bottlesInRackWithStaged = createSelector(
    bottlesInRack,
    selectedWineSelector,
    (appState) => appState.stagedBottlesAddRack,
    (appState) => appState.stagedBottlesRemoveRack,
    (appState) => appState.stagedBottlesToDrink,
    (
        bottlesSavedInRack,
        selectedWine,
        stagedBottlesAddRack,
        stagedBottlesRemoveRack,
        stagedBottlesToDrink
    ) => {
        // first reset all the bools
        const bottles = bottlesSavedInRack.map((b) => {
            b.stagedForDrinking = false;
            b.stagedForAdd = false;
            b.stagedForRemove = false;
            return b;
        });

        // then mark any that are staged for removal
        if (stagedBottlesRemoveRack && stagedBottlesRemoveRack.length) {
            stagedBottlesRemoveRack.forEach((sb) => {
                const bottleToUpdate = bottles.find(
                    (b) => b.colidx === sb.colidx && b.rowidx === sb.rowidx
                );
                bottleToUpdate.stagedForRemove = true;
            });
        }
        // then mark any staged for drinking
        if (stagedBottlesToDrink && stagedBottlesToDrink.length) {
            stagedBottlesToDrink.forEach((sbd) => {
                const bottleToUpdate = bottles.find(
                    (b) => b.colidx === sbd.colidx && b.rowidx === sbd.rowidx
                );
                if (bottleToUpdate) {
                    bottleToUpdate.stagedForDrinking = true;
                }
            });
        }

        // finally add in any that are staged for adding
        if (stagedBottlesAddRack && stagedBottlesAddRack.length) {
            stagedBottlesAddRack.forEach((b) => {
                b.stagedForAdd = true;
                bottles.push(b);
            });
        }

        return bottles.map((b) => {
            const isOfSelectedWine = selectedWine && selectedWine.id === b.wineDetails.id;
            b.isOfSelectedWine = isOfSelectedWine;
            return b;
        });
    }
);

// being lazy for now and just doing filtering client side
export const winesWithFilters = createSelector(
    collectionsLoading,
    (appState) => appState.wines,
    (appState) => appState.filters,
    (loading, wines, filters) => {
        if (loading || !wines) {
            return [];
        }
        const {
            withInventoryOnly,
            tags: filterTags,
            varietals: filterVarietals,
            types: filterTypes,
            year: filterYear,
            price: filterPrice,
            rack: showRackStatus,
        } = filters;
        return wines.filter((w) => {
            const { retail, cost, tag_ids, inventory, year, fk_varietal, fk_type } = w;
            const passesInventory = !withInventoryOnly || inventory > 0;
            const passesTags =
                !filterTags.length || (tag_ids && tag_ids.some((tid) => filterTags.includes(tid)));

            const passesVarietals =
                !filterVarietals.length || (fk_varietal && filterVarietals.includes(fk_varietal));
            const passesTypes = !filterTypes.length || (fk_type && filterTypes.includes(fk_type));

            let passesYear = true;
            if (filterYear.from || filterYear.to) {
                if (filterYear.from) {
                    passesYear = year >= filterYear.from;
                }
                if (passesYear && filterYear.to) {
                    passesYear = year <= filterYear.to;
                }
            }

            let passesPrice = true;
            if (filterPrice.from || filterPrice.to) {
                if (filterPrice.from) {
                    passesPrice = retail >= filterPrice.from || cost >= filterPrice.from;
                }
                if (passesPrice && filterPrice.to) {
                    passesPrice = retail <= filterPrice.to || cost <= filterPrice.to;
                }
            }
            // filter by rack is weird because true means include it
            //todo: this is probably not quite perfect
            let passesRack = true;
            if (!showRackStatus.inRack || !showRackStatus.outOfRack) {
                if (!showRackStatus.inRack) {
                    // remove those that are 100% in rack
                    const countOutofRack = getBottleCountNotInRack(w.bottles);
                    passesRack = countOutofRack > 0;
                }
                if (!showRackStatus.outOfRack) {
                    // remove those that are 100% in rack
                    const countOutofRack = getBottleCountNotInRack(w.bottles);
                    passesRack = !countOutofRack;
                }
            }

            return (
                passesInventory &&
                passesTags &&
                passesYear &&
                passesPrice &&
                passesRack &&
                passesVarietals &&
                passesTypes
            );
        });
    }
);

function getBottleCountInRack(bottles) {
    if (!bottles || bottles.length < 1) {
        return 0;
    }
    return bottles.filter((b) => b.colidx !== null && b.rowidx !== null).length;
}
function getBottleCountNotInRack(bottles) {
    if (!bottles || bottles.length < 1) {
        return 0;
    }
    return bottles.filter((b) => b.colidx === null || b.rowidx === null).length;
}

export const selectedFilterTags = createSelector(
    collectionsLoading,
    (appState) => appState.tags,
    (appState) => appState.filters,
    (loading, allTags, filters) => {
        if (loading || !allTags) {
            return [];
        }
        const { tags = [] } = filters;
        return allTags.filter((t) => tags.includes(Number(t.value)));
    }
);

export const unSelectedFilterTags = createSelector(
    collectionsLoading,
    (appState) => appState.tags,
    (appState) => appState.filters,
    (loading, allTags, filters) => {
        if (loading || !allTags) {
            return [];
        }
        const { tags = [] } = filters;
        return allTags.filter((t) => !tags.includes(Number(t.value)));
    }
);

export const selectedFilterVarietals = createSelector(
    collectionsLoading,
    (appState) => appState.varietals,
    (appState) => appState.filters,
    (loading, allVarietals, filters) => {
        if (loading || !allVarietals) {
            return [];
        }
        const { varietals = [] } = filters;
        return allVarietals.filter((v) => varietals.includes(Number(v.value)));
    }
);

export const unSelectedFilterVarietals = createSelector(
    collectionsLoading,
    (appState) => appState.varietals,
    (appState) => appState.filters,
    (loading, allVarietals, filters) => {
        if (loading || !allVarietals) {
            return [];
        }
        const { varietals = [] } = filters;
        return allVarietals.filter((v) => !varietals.includes(Number(v.value)));
    }
);

export const selectedFilterTypes = createSelector(
    collectionsLoading,
    (appState) => appState.types,
    (appState) => appState.filters,
    (loading, allTypes, filters) => {
        if (loading || !allTypes) {
            return [];
        }
        const { types = [] } = filters;
        return allTypes.filter((v) => types.includes(Number(v.value)));
    }
);

export const unSelectedFilterTypes = createSelector(
    collectionsLoading,
    (appState) => appState.types,
    (appState) => appState.filters,
    (loading, allTypes, filters) => {
        if (loading || !allTypes) {
            return [];
        }
        const { types = [] } = filters;
        return allTypes.filter((v) => !types.includes(Number(v.value)));
    }
);

// todo add in selector. probably should do this elsewhere...
function addTagsLabels(wines = [], tags = []) {
    return wines.map((w) => {
        const { tag_ids } = w;
        const hasTags = tag_ids && tag_ids.length;

        let tagLabels = '';
        if (hasTags) {
            tagLabels = tag_ids
                .map((id) => {
                    let tagObj = tags.find((t) => Number(t.value) == id);
                    return tagObj.label;
                })
                .join(', ');
        }
        const tagsMappedWine = {
            ...w,
            tagLabels,
        };
        return tagsMappedWine;
    });
}

export const winesWithFiltersAndMeta = createSelector(
    winesWithFilters,
    (appState) => appState.producers,
    (appState) => appState.varietals,
    (appState) => appState.types,
    (appState) => appState.tags,
    (filteredWines, producers = [], varietals = [], types = [], tags = []) => {
        if (!filteredWines) {
            return [];
        }
        const winesWithMeta = filteredWines.map((wine, idx) => {
            // first do basic transform
            const transformed = transformData({
                wine,
                producers,
                varietals,
                types,
                tags,
                //defaultValues: defaultInitialValues
            });

            transformed.imageData = getImageForWine(wine);
            transformed.tagLabels = getTagLabels(wine, tags);
            // todo figure out some neat ways to do featured
            // one way might be with num bottles
            transformed.featureTall = Math.random() <= 0.25;
            transformed.featureShort = Math.random() <= 0.25;
            return transformed;
        });
        return shuffle(winesWithMeta);
    }
);

function getTagLabels(w, tags = []) {
    const { tag_ids } = w;
    const hasTags = tag_ids && tag_ids.length;

    let tagLabels = '';
    if (hasTags) {
        tagLabels = tag_ids
            .map((id) => {
                let tagObj = tags.find((t) => Number(t.value) == id);
                return tagObj.label;
            })
            .join(', ');
    }
    return tagLabels;
}
