import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Wine from './Wine';
import WineList from './WineList';
import WineGridList from './WineGridList';
import InventoryActivity from './InventoryActivity';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import RackView from './RackView';
import { Loading } from '../util/Loading';
import { store, actions, selectors } from '../store/';
import Filters from './Filters';

function TabPanel(props) {
    const { children, value, index, maxWidth = 'md', ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Container maxWidth={maxWidth}>{children}</Container>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        //backgroundColor: 'cornsilk',
    },
    panelWithFilters: {
        // marginTop: -16,
    },
}));

export default function Cellar(props) {
    const classes = useStyles();
    const { dispatch, appState } = useContext(store);
    const defaultTab = 0;
    const [value, setValue] = useState(defaultTab);
    const isLoading = selectors.collectionsLoading(appState);
    const { wines } = appState;
    const { selectedWineId } = appState;
    const addEditString = selectedWineId ? 'Edit' : 'Add';

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    function formEdit(selectedWineId) {
        dispatch(actions.setSelectedWineId({ selectedWineId }));
        dispatch(actions.setEditMode({ wineEditMode: true }));
        setValue(3);
    }

    function formView(selectedWineId) {
        dispatch(actions.setSelectedWineId({ selectedWineId }));
        dispatch(actions.setEditMode({ wineEditMode: false }));
        setValue(3);
    }

    function rackView(selectedWineId) {
        dispatch(actions.setSelectedWineId({ selectedWineId }));
        setValue(2);
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Grid" />
                    <Tab label="List" />
                    <Tab label="Rack" />
                    <Tab label={addEditString} />
                    <Tab label="Activity" />
                    <Tab label="Todo" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.panelWithFilters}>
                {isLoading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <Filters />
                        <WineGridList rackView={rackView} />
                    </React.Fragment>
                )}
            </TabPanel>
            <TabPanel value={value} index={1} maxWidth="lg" className={classes.panelWithFilters}>
                {isLoading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <Filters />
                        <WineList formEdit={formEdit} formView={formView} rackView={rackView} />
                    </React.Fragment>
                )}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {isLoading ? <Loading /> : <RackView wines={wines} formEdit={formEdit} />}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {isLoading ? <Loading /> : <Wine formEdit={formEdit} formView={formView} />}
            </TabPanel>
            <TabPanel value={value} index={4}>
                <InventoryActivity />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <ul>
                    <li>tasting wheel</li>
                    <li>search at top</li>
                    <li>scrollable racks</li>
                    <li>sync filters to url, link to wine in url</li>
                    <li>show locations in front card, and/or minimap</li>
                    <li>handle muliple racks, allow rack name</li>
                    <li>more pinterest type organization</li>
                    <li>Stats on cost, value etc fun stuff</li>
                    <li>
                        use material table for collection editing, allow choose your bottle icon,
                        maybe color?
                    </li>
                    <li>integrate wine spectator vintage chart data</li>
                    <li>
                        fix collections trying to load before being authorized - this causes the
                        need for a manual refresh after login
                    </li>
                    <li>search but top level</li>
                    <li>maybe have the rack be zoomable like google maps</li>
                    <li>delete</li>
                </ul>
            </TabPanel>
        </div>
    );
}
