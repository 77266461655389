import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Loading } from '../util/Loading';
import { store } from '../store/';
import { DataContext } from '../data/DataProvider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 250,
    },
    paperRoot: {
        width: '100%',
        marginTop: 20,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function InventoryActivity() {
    const classes = useStyles();
    const dataContext = useContext(DataContext);
    //const { dataContext } = dataState;
    const { dispatch, appState } = useContext(store);
    const [reportData, setData] = useState([]);

    const [reportType, setReportType] = React.useState(1);
    const handleChange = (event) => {
        setReportType(event.target.value);
    };

    async function fetchData() {
        const invDataArray = await dataContext.reportApi(`inventoryActivity/${reportType}`);
        setData(invDataArray);
    }

    useEffect(() => {
        fetchData();
    }, [reportType]);

    return (
        <Paper className={classes.paperRoot}>
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel>Report Type</InputLabel>
                    <Select variant="outlined" value={reportType} onChange={handleChange}>
                        <MenuItem value={1}>Drinking</MenuItem>
                        <MenuItem value={2}>Adjustments</MenuItem>
                        <MenuItem value={3}>Initial Adds</MenuItem>
                        <MenuItem value={4}>Adds and Adjustments</MenuItem>
                        <MenuItem value={5}>All</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Reason</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell>Producer</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Varietal</TableCell>
                        <TableCell align="right">Year</TableCell>
                        <TableCell align="right">Qty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reportData.map((row, idx) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.reason}</TableCell>
                            <TableCell align="right">{row.txn_date}</TableCell>
                            <TableCell>{row.producer}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.varietal}</TableCell>
                            <TableCell align="right">{row.year}</TableCell>
                            <TableCell align="right">{row.qty}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}
