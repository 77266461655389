import React, { useEffect, useState, useContext } from 'react';
import { DataContext } from './data/';
import { makeStyles } from '@material-ui/core/styles';
import Dashboard from './dashboard/Dasboard';
import Notes from './notes/Notes';
import Cellar from './cellar/Cellar';
import Home from './dashboard/Home';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { store } from './store/';

import {
    //LoggedOutRoute,
    AuthorizedRoute,
    PropsRoute,
} from './util/RouteUtil';

import {
    Route,
    Redirect,
    //withRouterk
} from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DashboardIcon from 'mdi-material-ui/Creation';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NotesIcon from 'mdi-material-ui/GlassWine';
import CellarIcon from 'mdi-material-ui/BottleWine';

const useStyles = makeStyles((theme) => ({
    flex: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    tabArea: {
        maxWidth: 400,
    },
    tabsRoot: {
        maxWidth: 575,
    },
    tabRoot: {
        minWidth: 50,
        /*maxWidth:95*/
    },
    mainLink: {
        textDecoration: 'none',
        '&:hover': {
            //textDecoration: 'underline',
            color: theme.palette.secondary.main,
        },
        color: theme.palette.primary.contrastText,
    },
}));

// todo last thing I need to do here is default the route when the user is first logged in
// currently the routes render before the auth is set and then when it tries to use location it fails
// need to cache the requested location until the auth process has completed

export default function App(props) {
    const classes = useStyles();
    const { appState } = useContext(store);
    const dataContext = useContext(DataContext);
    const { user } = appState;
    const { isAuthenticated } = user;
    const [collectionLoadingInitiated, setCollectionStatus] = useState(false);

    async function fetchData() {
        // this only needs to be done once, could probably do a clean up step
        // (clear) on logout
        if (isAuthenticated && !collectionLoadingInitiated) {
            dataContext.loadAllCollections();
            // this is just so we don't call loadAllCollections twice
            setCollectionStatus(true);
        }
    }

    useEffect(() => {
        fetchData();
    }, [isAuthenticated]);

    const history = useHistory();

    function handleChange(e, value) {
        history.push(value);
    }

    const location = useLocation();
    function tabsValue() {
        const path = location.pathname;
        const possibleTabsVals = ['/cellar', '/notes', '/dashboard', '/login'];
        return path && possibleTabsVals.includes(path) ? path : false;
    }

    const tabValue = tabsValue();

    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h5" className={classes.flex}>
                        <Link className={classes.mainLink} to="/login">
                            Bacchus
                        </Link>
                    </Typography>
                    {isAuthenticated && (
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            classes={{ root: classes.tabsRoot }}
                        >
                            <Tab
                                value="/dashboard"
                                classes={{ root: classes.tabRoot }}
                                label="Dashboard"
                                icon={<DashboardIcon />}
                                component={Link}
                                to="/dashboard"
                            />
                            <Tab
                                classes={{ root: classes.tabRoot }}
                                value="/cellar"
                                label="Cellar"
                                icon={<CellarIcon />}
                                component={Link}
                                to="/cellar"
                            />
                            <Tab
                                value="/notes"
                                classes={{ root: classes.tabRoot }}
                                label="Notes"
                                icon={<NotesIcon />}
                                component={Link}
                                to="/notes"
                            />
                        </Tabs>
                    )}
                    {!isAuthenticated && (
                        <Tabs value={tabValue}>
                            <Tab value="/login" label="Home" component={Link} to="/" />
                        </Tabs>
                    )}
                </Toolbar>
            </AppBar>
            <div>
                <Route exact path="/">
                    {isAuthenticated ? <Redirect to="/cellar" /> : <Redirect to="/login" />}
                </Route>
                <AuthorizedRoute exact path="/cellar" component={Cellar} />
                <AuthorizedRoute path="/dashboard" component={Dashboard} />
                <AuthorizedRoute exact path="/notes" component={Notes} />
                <PropsRoute exact path="/login" component={Home} />
            </div>
        </React.Fragment>
    );
}

/*
import PersonPinIcon from '@material-ui/icons/PersonPin';
//really like this icon
import ReportsIcon from 'mdi-material-ui/ChartGantt';
import DashboardIcon from 'mdi-material-ui/Creation';
*/
