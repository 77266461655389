import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { store, actions, selectors } from '../store/';
import { WineSubDetails, DescriptionPanel } from './WineDetails';
import InventoryControls from './InventoryControls';

import Typography from '@material-ui/core/Typography';
import WineImage from './WineImage';

import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //marginTop: 20,
    },
    inventoryContainer: {
        maxHeight: 250,
    },
    title: {
        marginBottom: 4,
        fontSize: 18,
        whiteSpace: 'nowrap',
        overflow: 'ellipsis',
    },
    expansionSummary: {
        '&$expanded': {
            margin: '10px 0',
        },
        margin: '10px 0',
    },
    expanded: {},
    expanderButton: {
        '&$expanded': {
            alignSelf: 'flex-end',
        },
        alignSelf: 'flex-end',
    },
}));

export default function WineView({ formEdit, ...rest }) {
    const { dispatch, appState } = useContext(store);
    const classes = useStyles();
    const wine = selectors.selectedWineSelector(appState);
    const { rackAddMode, rackRemoveMode, drinkMode } = appState;

    const { name, producer, varietal, year, inventory, description } = wine;
    const hasSelectedWine = wine && wine.id > 0;
    const inventoryString = inventory ? `${inventory} bottles` : 'no inventory';
    const titleClasses = clsx({
        [classes.title]: true,
    });
    const showDescription = description && description.trim() !== '';
    const summaryClasses = {
        content: classes.expansionSummary,
        expandIcon: classes.expanderButton,
        expanded: classes.expanded,
    };
    return (
        hasSelectedWine && (
            <ExpansionPanel className={classes.root}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={summaryClasses}>
                    <Grid
                        item
                        container
                        direction="row"
                        justify="space-between"
                        className={classes.titleContainer}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <WineImage size="small" variant="logoSquare" wine={wine} />
                        </Grid>
                        <Grid item container xs="auto" justify="center" alignContent="center">
                            <div>
                                <Typography className={titleClasses} variant="h5">
                                    {year} {producer.label} {name} {varietal && varietal.label}
                                </Typography>
                                <Typography variant="subtitle1">{inventoryString}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails
                    className={classes.details}
                    className={classes.expansionDetails}
                >
                    <Grid container item spacing={2}>
                        <Grid
                            container
                            item
                            spacing={1}
                            justify="space-between"
                            direction="row"
                            wrap="nowrap"
                        >
                            <Grid container item direction="column" spacing={1}>
                                <WineSubDetails wine={wine} />
                            </Grid>
                        </Grid>
                        {showDescription && (
                            <Grid container item className={classes.detailsContainer} xs="auto">
                                <DescriptionPanel description={description} classes={classes} />
                            </Grid>
                        )}
                    </Grid>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Grid
                        item
                        container
                        direction="row"
                        spacing={1}
                        xs
                        alignItems="flex-end"
                        className={classes.buttonRow}
                    >
                        <InventoryControls />
                        <Grid item>
                            <Button
                                size="small"
                                color="secondary"
                                variant="outlined"
                                onClick={() => formEdit(wine.id)}
                            >
                                Edit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="small"
                                color="secondary"
                                variant="outlined"
                                onClick={() =>
                                    dispatch(actions.setSelectedWineId({ selectedWineId: 0 }))
                                }
                            >
                                Unselect
                            </Button>
                        </Grid>
                    </Grid>
                </ExpansionPanelActions>
                <Grid container item justify="center">
                    {drinkMode && 'Select a wine to drink'}
                    {rackAddMode && 'Select open slots to place wines'}
                    {rackRemoveMode && 'Select bottles to remove from rack'}
                </Grid>
            </ExpansionPanel>
        )
    );
}
