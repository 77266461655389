import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import WineView from './WineView';
import Rack from './Rack';
import { DataContext } from '../data/';
import { store, actions, selectors } from '../store/';
import InventoryControls from './InventoryControls';
import BottlesView from './BottlesView';

import CustomSnackbar from '../util/CustomSnackbar';

const useStyles2 = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
    },
    paper: {
        height: 40,
        width: 40,
        padding: 4,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },
    rackRow: {
        marginTop: 4,
        marginBottom: 4,
    },
    control: {
        padding: theme.spacing(2),
    },
    stickyHead: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    contentRow: {
        marginTop: 12,
        scrollMarginTop: 140,
    },
}));

export default function RackView({ wines = [], ...rest }) {
    const classes = useStyles2();
    const dataContext = useContext(DataContext);
    const { dispatch, appState } = useContext(store);
    const { rackAddMode, drinkMode, stagedBottlesAddRack } = appState;
    const selectedWine = selectors.selectedWineSelector(appState);
    const availableBottles = selectors.selectedWineAvailableBottles(appState);
    const [toastOpen, setToastOpen] = React.useState(false);
    const toastMsg = '';

    function handleToastClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    }
    const bottles = selectors.bottlesInRackWithStaged(appState);
    const rackProps = {
        rackAddMode,
        drinkMode,
        bottles,
        stagedBottlesAddRack,
        availableBottles,
    };

    return (
        <React.Fragment>
            <Grid container className={classes.root} spacing={0}>
                <Grid item container justify="center" className={classes.stickyHead}>
                    {selectedWine && <WineView {...rest} />}
                </Grid>
                <Grid item container justify="center" className={classes.contentRow}>
                    <BottlesView />
                </Grid>
                <Grid item container justify="center" className={classes.contentRow}>
                    <Rack {...rackProps} />
                </Grid>
            </Grid>
            <CustomSnackbar
                open={toastOpen}
                onClose={handleToastClose}
                variant="success"
                message={toastMsg}
            />
        </React.Fragment>
    );
}
