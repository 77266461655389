import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getSettings } from '../util/Settings';
import RackRow from './RackRow';
// exampleComponent.js
import { store, actions } from '../store/';
import { DataContext } from '../data/DataProvider';
import {
    Paper,
    Grid,
    Button,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';

import classNames from 'classnames';
import { CopyIcon, BurgundyBottle } from '../icons/SVGIcons';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    shelf: {
        margin: '0px 0px 0.25em',
        fontWeight: 400,
        position: 'relative',
        fontSize: '.9rem',
        // lineHeight: 20px,
        padding: 4,
        //color: rgb(53, 86, 129);
        fontFamily: 'Lato, sans-serif',
        //borderBottom: '1px solid rgba(53, 86, 129, 0.3)',
        borderBottom: '1px solid rgba(127, 0, 0, 0.5)',
    },
}));

function findBottleForSlot(bottles, colidx, rowidx) {
    const rackDataItem = bottles.find((b) => {
        return b && b.colidx === colidx && b.rowidx == rowidx;
    });

    return rackDataItem;
}

// function findPendingBottleForSlot(bottles, colidx, rowidx) {
//     const rackDataItem = bottles.find(b => {
//         return b.colidx === colidx && b.rowidx == rowidx;
//     });

//     return rackDataItem;
// }

function Rack({ bottles, stagedBottlesAddRack, ...rest }) {
    // const globalState = useContext(store);
    // const { dispatch, appState } = globalState;

    bottles = bottles || [];
    const settings = getSettings();
    const classes = useStyles();
    const spacing = 1;
    const rackParams = settings.racks[0];
    const { shortShelves } = rackParams;
    const rackRows = [];
    for (let rowidx = 0; rowidx < rackParams.rows; rowidx++) {
        const row = [];
        //Inner loop to create children
        for (let colidx = 0; colidx < rackParams.cols; colidx++) {
            const bottleAtPosition = findBottleForSlot(bottles, colidx, rowidx);
            const slotData = {
                rowidx,
                colidx,
                bottleAtPosition,
            };
            row.push(slotData);
        }
        //Create the parent and add the children
        rackRows.push(row);
    }
    let shelfBreakCounter = 0;
    let shelfNumber = 0;
    return (
        <Grid item container className={classes.root} component={Paper}>
            <Grid item container justify="space-around" alignItems="center" spacing={spacing}>
                {rackRows.map((row, idx) => {
                    shelfBreakCounter++;
                    const isShortShelf = shortShelves.includes(shelfNumber);
                    if (shelfBreakCounter === 1 || isShortShelf) {
                        if (isShortShelf) {
                            // just hack to make it create another shelf next time
                            shelfBreakCounter = 1;
                        }
                        shelfNumber++;
                        return (
                            <div>
                                <h3 className={classes.shelf}>Shelf {shelfNumber} </h3>
                                <RackRow row={row} rowidx={idx} key={idx} {...rest} />
                            </div>
                        );
                    } else {
                        shelfBreakCounter = 0;
                        return <RackRow row={row} rowidx={idx} key={idx} {...rest} />;
                    }
                })}
            </Grid>
        </Grid>
    );
}

export default Rack;
