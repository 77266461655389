import React from 'react';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    slot: {
        margin: 4,
    },
    buttonStyles: {
        width: 50,
        height: 50,
        padding: 5,
    },
}));

function ImgurUpload({ onUploadComplete }) {
    const classes = useStyles();
    //const { dispatch, appState } = useContext(store);
    //const { rackAddMode, rackRemoveMode, drinkMode } = appState;
    // borrowed from https://github.com/MiguhRuiz/react-imgur-upload, could use axios or whatever here if desired
    // see also  https://medium.com/@microaeris/getting-started-with-the-imgur-api-4e96c352658a
    // and imgur api docs
    // NOTE THESE are public/anonymous images now, we could probably do private as well but would be trickier
    const IMGUR_CLIENT_ID = `1704b1933693816`;
    function uploadImage(e) {
        const r = new XMLHttpRequest();
        const d = new FormData();
        //const e = document.getElementsByClassName('input-image')[0].files[0];
        //console.log(e.target.files);
        const fileObj = e.target.files[0];
        //return;
        //var u;

        d.append('image', fileObj);

        r.open('POST', 'https://api.imgur.com/3/image/');
        r.setRequestHeader('Authorization', `Client-ID ${IMGUR_CLIENT_ID}`);
        r.onreadystatechange = function () {
            if (r.status === 200 && r.readyState === 4) {
                const res = JSON.parse(r.responseText);
                const url = `https://i.imgur.com/${res.data.id}.png`;
                //console.log(res);
                onUploadComplete(url);

                // const d = document.createElement('div');
                // d.className = 'image';
                // document.getElementsByTagName('body')[0].appendChild(d);

                // const i = document.createElement('img');
                // i.className = 'image-src';
                // i.src = u;
                // document.getElementsByClassName('image')[0].appendChild(i);

                // const a = document.createElement('a');
                // a.className = 'image-link';
                // a.href = u;
                // document.getElementsByClassName('image')[0].appendChild(a);

                // const p = document.createElement('p');
                // p.className = 'image-url';
                // p.innerHTML = u;
                // document.getElementsByClassName('image-link')[0].appendChild(p);
            }
        };
        r.send(d);
    }

    return (
        <Tooltip title="Upload Image to Imgur (NOTE: these are public )">
            <div>
                <FileButton onChange={uploadImage} />
            </div>
        </Tooltip>
    );
}
{
    /* <div>
<input type="file" onChange={uploadImage} />
</div> */
}
const useStylesButton = makeStyles((theme) => ({
    // button: {
    //     margin: theme.spacing.unit
    // },
    input: {
        display: 'none',
    },
}));

function FileButton({ onChange }) {
    const classes = useStylesButton();
    return (
        <div>
            <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                onChange={onChange}
                type="file"
            />
            <label htmlFor="contained-button-file">
                <Button variant="outlined" component="span">
                    <AddAPhotoOutlinedIcon />
                </Button>
            </label>
        </div>
    );
}
{
    /* <Button component="span" className={classes.button}>
Upload
</Button> */
}
export default ImgurUpload;
/*
                <Button
                    {...buttonProps}
                    classes={baseButtonClasses}
                    className={classes.buttonStyles}
                >
                    <AddIcon fontSize="small" />
                </Button>
*/
// class App extends Component {
//     render() {
//         return (
//             <div className="App">
//                 <div className="App-header">
//                     <img src={logo} className="App-logo" alt="logo" />
//                     <h2>Elige una imagen para subir a Imgur</h2>
//                 </div>
//                 <form>
//                     <input
//                         type="file"
//                         className="input-image"
//                         onChange={this.uploadImage.bind(this)}
//                     />
//                 </form>
//             </div>
//         );
//     }
// }
