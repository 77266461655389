//import React, { Component } from 'react';

import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../data/';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { store, actions, selectors } from '../store/';
import { capitalizeFirstLetterDropLast } from './helpers';

const customStyles = {
    menu: styles => Object.assign(styles, { zIndex: 1000 })
};

export default function AutoComplete({ allowCreate = true, ...rest }) {
    return allowCreate ? <AutoCompleteWithCreate {...rest} /> : <AutoCompleteBasic {...rest} />;
}

// same as createable except create new not allowed
function AutoCompleteBasic({
    collectionName,
    placeholder,
    value,
    className,
    isMulti,
    onChange,
    onBlur,
    error,
    touched,
    name
}) {
    const { dispatch, appState } = useContext(store);

    function handleChange(value) {
        onChange(name, value);
    }

    function handleBlur() {
        onBlur(name, true);
    }

    const collection = appState[collectionName];
    const isLoading = collection == null;
    const options = isLoading ? [] : collection;
    return (
        <div className={className}>
            <Select
                placeholder={placeholder}
                isDisabled={isLoading}
                isLoading={isLoading}
                isClearable
                options={options}
                value={value}
                styles={customStyles}
                isMulti={isMulti}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {!!error && touched && <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>}
        </div>
    );
}

function AutoCompleteWithCreate({
    collectionName,
    placeholder,
    value,
    className,
    isMulti,
    onChange,
    onBlur,
    error,
    touched,
    name
}) {
    const [isAdding, setIsAdding] = useState(false);
    const dataContext = useContext(DataContext);
    const { dispatch, appState } = useContext(store);

    function handleChange(value) {
        onChange(name, value);
    }

    function handleBlur() {
        onBlur(name, true);
    }

    async function handleCreate(inputValue) {
        //convention based naming.. basically producers and addProducer
        const addMethodName = `add${capitalizeFirstLetterDropLast(collectionName)}`;
        setIsAdding(true);
        const newValue = await dataContext[addMethodName](inputValue);
        setIsAdding(false);

        if (isMulti) {
            const newValues = value.concat(newValue);
            onChange(name, newValues);
        } else {
            onChange(name, newValue);
        }
    }

    // todo: option to not make this a create new, looks like react-select has that (i.e. this is a special case)
    const collection = appState[collectionName];
    const isLoading = collection == null || isAdding;
    const options = isLoading ? [] : collection;
    return (
        <div className={className}>
            <CreatableSelect
                placeholder={placeholder}
                isClearable
                isMulti={isMulti}
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={handleChange}
                onBlur={handleBlur}
                onCreateOption={handleCreate}
                options={options}
                value={value}
                styles={customStyles}
            />
            {!!error && touched && <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>}
        </div>
    );
}

// export class SingleSelect extends Component {
//     state = {
//         isClearable: true,
//         isDisabled: false,
//         isLoading: false,
//         isRtl: false,
//         isSearchable: true
//     };

//     toggleClearable = () => this.setState(state => ({ isClearable: !state.isClearable }));
//     toggleDisabled = () => this.setState(state => ({ isDisabled: !state.isDisabled }));
//     toggleLoading = () => this.setState(state => ({ isLoading: !state.isLoading }));
//     toggleRtl = () => this.setState(state => ({ isRtl: !state.isRtl }));
//     toggleSearchable = () => this.setState(state => ({ isSearchable: !state.isSearchable }));
//     render() {
//         const { isClearable, isSearchable, isDisabled, isLoading, isRtl } = this.state;
//         return (
//             <Fragment>
//                 <Note Tag="label">
//                     <Checkbox
//                         checked={isClearable}
//                         onChange={this.toggleClearable}
//                         id="cypress-single__clearable-checkbox"
//                     />
//                     Clearable
//                 </Note>
//                 <Note Tag="label" style={{ marginLeft: '1em' }}>
//                     <Checkbox
//                         checked={isSearchable}
//                         onChange={this.toggleSearchable}
//                         id="cypress-single__searchable-checkbox"
//                     />
//                     Searchable
//                 </Note>
//                 <Note Tag="label" style={{ marginLeft: '1em' }}>
//                     <Checkbox
//                         checked={isDisabled}
//                         onChange={this.toggleDisabled}
//                         id="cypress-single__disabled-checkbox"
//                     />
//                     Disabled
//                 </Note>
//                 <Note Tag="label" style={{ marginLeft: '1em' }}>
//                     <Checkbox
//                         checked={isLoading}
//                         onChange={this.toggleLoading}
//                         id="cypress-single__loading-checkbox"
//                     />
//                     Loading
//                 </Note>
//                 <Note Tag="label" style={{ marginLeft: '1em' }}>
//                     <Checkbox
//                         type="checkbox"
//                         checked={isRtl}
//                         onChange={this.toggleRtl}
//                         id="cypress-single__rtl-checkbox"
//                     />
//                     RTL
//                 </Note>
//             </Fragment>
//         );
//     }
// }
