import React, { useContext, useState, useEffect } from 'react';
import { WineEdit } from './WineEdit';
import WineView from './WineView';
import { DataContext } from '../data/';
import { store, actions } from '../store/';

export default function Wine(props) {
    const dataContext = useContext(DataContext);
    //const { dataContext } = dataState;
    const { dispatch, appState } = useContext(store);
    const { selectedWineId, wineEditMode } = appState;
    const noDataPresent = !selectedWineId; // for the add new case where we have no data
    const isEditMode = noDataPresent || wineEditMode;

    return isEditMode ? <WineEdit {...props} /> : <WineView {...props} />;
}
