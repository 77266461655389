import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const chipStyles = makeStyles((theme) => ({
    root: {},
    chip: {},
}));

function ToggleChip({ isOn, onLabel, offLabel, onToggle, name }) {
    const classes = chipStyles();
    const toggler = () => onToggle(name);
    return isOn ? (
        <Chip
            label={onLabel}
            onDelete={toggler}
            onClick={toggler}
            color="primary"
            className={classes.chip}
            variant="outlined"
        />
    ) : (
        <Chip label={offLabel} onClick={toggler} className={classes.chip} />
    );
}

export default ToggleChip;
