import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RackTooltip from './RackTooltip';
import { Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BottleButton from './BottleButton';
import { store, actions } from '../store/';
import DrinkDialog from './DrinkDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    slot: {
        margin: 4,
    },
    buttonStyles: {
        width: 50,
        height: 50,
        padding: 5,
    },
}));

const useBaseButtonStyles = makeStyles((theme) => ({
    root: {
        // padding: '5px 6px',
        minWidth: 50,
    },
}));

function RackSlot({ availableBottles, bottleAtPosition, colidx, rowidx }) {
    const classes = useStyles();
    const baseButtonClasses = useBaseButtonStyles();
    const { dispatch, appState } = useContext(store);
    const { rackAddMode, rackRemoveMode, drinkMode } = appState;

    const [drinkDialogOpen, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
        //setSelectedBottle(null);
    }

    function showDrinkDialog() {
        //setSelectedBottle(bottleAtPosition);
        setOpen(true);
    }

    function handleRackItemClick() {
        // if (drinkMode) {
        //     if (bottleAtPosition) {
        //         if (bottleAtPosition.stagedForDrinking) {
        //             handleUnStageForDrinking();
        //         } else {
        //             handleStageForDrinking();
        //         }
        //     }
        //     return;
        // }

        if (rackAddMode) {
            if (bottleAtPosition) {
                if (bottleAtPosition.stagedForAdd) {
                    handleUnPlaceBottle();
                } else {
                    // ?
                }
                return;
            } else {
                handlePlaceBottle();
                return;
            }
        }
        if (rackRemoveMode) {
            if (bottleAtPosition) {
                if (bottleAtPosition.stagedForRemove) {
                    handleUnStageForRemove();
                } else {
                    handleStageForRemove();
                }
                return;
            }
        }
        // else...
        if (bottleAtPosition.fk_wine) {
            const selectedWineId = bottleAtPosition.fk_wine;
            dispatch(actions.setSelectedWineId({ selectedWineId }));
            showDrinkDialog();
        }
    }

    let disabled = true;
    if (rackAddMode) {
        //!rackAddMode && !bottleAtPosition;
        disabled = bottleAtPosition && !bottleAtPosition.stagedForAdd;
    } else {
        disabled = !bottleAtPosition;
    }

    const buttonProps = {
        onClick: handleRackItemClick,
        variant: 'outlined',
        disabled,
    };

    function handlePlaceBottle() {
        if (availableBottles && availableBottles.length == 0) {
            //todo: make fancy :)
            alert('No more bottles');
            return;
        }
        dispatch(actions.stageBottleAddRack({ rowidx, colidx }));
    }

    function handleUnPlaceBottle() {
        dispatch(actions.unstageBottleAddRack({ rowidx, colidx }));
    }

    function handleStageForDrinking() {
        dispatch(actions.stageBottleDrink({ fromRack: true, rowidx, colidx }));
    }

    function handleUnStageForDrinking() {
        dispatch(actions.unstageBottleDrink({ fromRack: true, rowidx, colidx }));
    }

    function handleStageForRemove() {
        dispatch(actions.stageBottleRemoveRack({ rowidx, colidx }));
    }

    function handleUnStageForRemove() {
        dispatch(actions.unstageBottleRemoveRack({ rowidx, colidx }));
    }

    return (
        <Grid className={classes.slot} item>
            {bottleAtPosition ? (
                <React.Fragment>
                    <RackTooltip wine={bottleAtPosition.wineDetails}>
                        <div>
                            <BottleButton {...buttonProps} bottle={bottleAtPosition} />
                        </div>
                    </RackTooltip>
                    <DrinkDialog
                        open={drinkDialogOpen}
                        handleClose={handleClose}
                        bottle={bottleAtPosition}
                    />
                </React.Fragment>
            ) : (
                <Button
                    {...buttonProps}
                    classes={baseButtonClasses}
                    className={classes.buttonStyles}
                >
                    <AddIcon fontSize="small" />
                </Button>
            )}
        </Grid>
    );
}
/*
: pendingBottleAtPosition ? (
                <BottleButton {...buttonProps} isStagedRackChange={pendingBottleAtPosition} />

*/
export default RackSlot;
