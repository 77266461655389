import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { store, actions, selectors } from '../store/';
import { DataContext } from '../data/DataProvider';

const inventoryControlsStyles = makeStyles((theme) => ({
    outerContainer: {
        alignSelf: 'stretch',
    },
}));

export default function InventoryControls() {
    const classes = inventoryControlsStyles();
    const dataContext = useContext(DataContext);
    const { dispatch, appState } = useContext(store);
    const availableBottles = selectors.selectedWineAvailableBottles(appState);
    const selectedWine = selectors.selectedWineSelector(appState);
    const hasUnmappedBottles = !!(availableBottles && availableBottles.length);
    const {
        rackAddMode,
        rackRemoveMode,
        drinkMode,
        stagedBottlesAddRack = [],
        stagedBottlesRemoveRack = [],
        stagedBottlesToDrink = [],
    } = appState;

    function saveRackChanges() {
        if (stagedBottlesAddRack) {
            saveStagedAdds();
        }
        if (stagedBottlesRemoveRack) {
            saveStagedRemovals();
        }
        if (stagedBottlesToDrink) {
            saveStagedDrinking();
        }
        exitRackModes();
    }

    function saveStagedAdds() {
        const inventoryPositions = stagedBottlesAddRack.map((b) => {
            return {
                id: b.id,
                rowidx: b.rowidx,
                colidx: b.colidx,
            };
        });
        const dataToSave = {
            id: selectedWine.id,
            positions: inventoryPositions,
        };
        dataContext.updateInventoryPosition(dataToSave);
    }

    function saveStagedRemovals() {
        const inventoryPositions = stagedBottlesRemoveRack.map((b) => {
            return {
                id: b.id,
            };
        });
        const dataToSave = {
            positions: inventoryPositions,
        };
        dataContext.removeInventoryPositions(dataToSave);
    }

    function saveStagedDrinking() {
        const bottles = stagedBottlesToDrink.map((b) => {
            return {
                bottleId: b.id,
                wineId: b.fk_wine,
            };
        });
        const dataToSave = {
            bottles,
        };
        dataContext.drinkWine(dataToSave);
    }

    function enterAddRackMode() {
        dispatch(actions.setRackAddMode({ rackAddMode: true }));
    }

    function enterRemoveRackMode() {
        dispatch(actions.setRackRemoveMode({ rackRemoveMode: true }));
    }

    function enterDrinkMode() {
        dispatch(actions.setDrinkMode({ drinkMode: true }));
    }
    // kind of a hack, just doing them all regardless here
    function exitRackModes() {
        dispatch(actions.setRackAddMode({ rackAddMode: false }));
        dispatch(actions.setRackRemoveMode({ rackRemoveMode: false }));
        dispatch(actions.setDrinkMode({ drinkMode: false }));
    }

    return (
        <React.Fragment>
            {/* {!rackAddMode && !rackRemoveMode && !drinkMode && (
                <Grid item>
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={enterDrinkMode}
                    >
                        Drink
                    </Button>
                </Grid>
            )} */}
            {hasUnmappedBottles && !rackAddMode && !rackRemoveMode && !drinkMode && (
                <Grid item>
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={enterAddRackMode}
                    >
                        Add to Rack
                    </Button>
                </Grid>
            )}
            {!rackAddMode && !rackRemoveMode && !drinkMode && (
                <Grid item>
                    <Button
                        size="small"
                        color="secondary"
                        onClick={enterRemoveRackMode}
                        variant="outlined"
                    >
                        Remove from Rack
                    </Button>
                </Grid>
            )}
            {(rackAddMode || rackRemoveMode || drinkMode) && (
                <React.Fragment>
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={saveRackChanges}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={exitRackModes}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
